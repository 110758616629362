import React from 'react';
import { useTranslation } from 'react-i18next';
import './WhatAlsaMindDoes.css';

function WhatAlsaMindDoes() {
    const { t } = useTranslation();

    return (
        <section className="what-alsamind-does" id="about">
            <h2>{t('whatAlsaMindDoes.title')}</h2>
            <div className="columns">
                <div className="column">
                    <h3>{t('whatAlsaMindDoes.programTitle')}</h3>
                    <p>{t('whatAlsaMindDoes.programDescription')}</p>
                </div>
                <div className="column">
                    <h3>{t('whatAlsaMindDoes.pastTitle')}</h3>
                    <p>{t('whatAlsaMindDoes.pastDescription')}</p>
                </div>
                <div className="column">
                    <h3>{t('whatAlsaMindDoes.futureTitle')}</h3>
                    <p>{t('whatAlsaMindDoes.futureDescription')}</p>
                </div>
            </div>
        </section>
    );
}

export default WhatAlsaMindDoes;
