// THIS IS FOR WHEN A USER'S PROGRAM CANNOT BE FOUND
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header1 from './components/Header';
import './css/AgeRestrictionPage.css';
import { useTranslation } from 'react-i18next';

function LocationRestrictionPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        navigate('/');
    };

    return (
        <div>
            <Header1 />
            <div className="content">
                <h2>{t('locationrestriction.warning')}</h2>
                <p>{t('locationrestriction.message')}</p>
                <button className='okbutton' onClick={handleClose}>{t('locationrestriction.okButton')}</button>
            </div>
        </div>
    );
}

export default LocationRestrictionPage;
