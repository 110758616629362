import React, { useState, useEffect, useMemo, useCallback } from 'react';
import i18n from './i18n';

const LanguageContext = React.createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.split('-')[0];
        const supportedLanguages = ['en', 'fr', 'de', 'es', 'it', 'pt', 'cn', 'jp'];
        const initialLanguage = savedLanguage || (supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en');

        setLanguage(initialLanguage);

        const changeLanguage = async (lang) => {
            try {
                await i18n.changeLanguage(lang);
            } catch (error) {
                console.error('Error changing language:', error);
            }
        };

        changeLanguage(initialLanguage);

        if (!savedLanguage) {
            localStorage.setItem('language', initialLanguage);
        }
    }, []);

    const changeLanguage = useCallback((newLanguage) => {
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage).catch((error) => {
            console.error('Error changing language:', error);
        });
        localStorage.setItem('language', newLanguage);
    }, []);

    const value = useMemo(() => ({
        language,
        changeLanguage
    }), [language, changeLanguage]);

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => React.useContext(LanguageContext);

export default LanguageContext;
