// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PurchasePage.css */
.purchase-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    padding: 20px;
    background-color: #f0f0f0;
}

.left-half,
.right-half {
    flex: 1 1;
    padding: 20px;
}

.left-half {
    border-right: 1px solid #ddd;
}

.right-half {
    padding-left: 40px;
}

.purchase-form,
.product-info,
.payment-form {
    width: 100%;
}

.form-section {
    margin-bottom: 20px;
}

.form-section h2,
.product-info h2 {
    margin-bottom: 10px;
}

.form-section label,
.product-info p {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.form-section input,
.payment-form button {
    padding: 10px;
    font-size: 16px;
}

.payment-form button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.payment-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/css/PurchasePage.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAO;IACP,aAAa;IACb,yBAAyB;AAC7B;;AAEA;;IAEI,SAAO;IACP,aAAa;AACjB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;;IAGI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;IAEI,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":["/* PurchasePage.css */\n.purchase-page {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.content {\n    display: flex;\n    justify-content: space-between;\n    flex: 1;\n    padding: 20px;\n    background-color: #f0f0f0;\n}\n\n.left-half,\n.right-half {\n    flex: 1;\n    padding: 20px;\n}\n\n.left-half {\n    border-right: 1px solid #ddd;\n}\n\n.right-half {\n    padding-left: 40px;\n}\n\n.purchase-form,\n.product-info,\n.payment-form {\n    width: 100%;\n}\n\n.form-section {\n    margin-bottom: 20px;\n}\n\n.form-section h2,\n.product-info h2 {\n    margin-bottom: 10px;\n}\n\n.form-section label,\n.product-info p {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n}\n\n.form-section input,\n.payment-form button {\n    padding: 10px;\n    font-size: 16px;\n}\n\n.payment-form button {\n    margin-top: 20px;\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.payment-form button:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
