import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { collection, query, where, getDocs, setDoc, doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const domain = process.env.REACT_APP_DOMAIN;

function AddSomeonePage() {
    const { currentUser } = useAuth();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [relationshipStatus, setRelationshipStatus] = useState('');
    const [nickname, setNickname] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [requestPending, setRequestPending] = useState(false);

    const navigate = useNavigate();

    const statusMap = [
        { value: '1', label: t('add_manual_person.status.love') },
        { value: '2', label: t('add_manual_person.status.friend') },
        { value: '3', label: t('add_manual_person.status.parent') },
        { value: '4', label: t('add_manual_person.status.child') },
        { value: '5', label: t('add_manual_person.status.family') },
        { value: '6', label: t('add_manual_person.status.boss') },
        { value: '7', label: t('add_manual_person.status.employee') },
        { value: '8', label: t('add_manual_person.status.colleague') },
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!username || !relationshipStatus || !nickname) {
            setError(t('add_someone.error.fill_all_fields'));
            return;
        }

        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const usernameQuery = query(collection(db, 'ep'), where('username', '==', username));
            const usernameSnapshot = await getDocs(usernameQuery);

            if (usernameSnapshot.empty) {
                setError(t('add_someone.error.username_not_exist'));
                setLoading(false);
                return;
            }

            const targetUserId = usernameSnapshot.docs[0].id;
            const targetUsername = usernameSnapshot.docs[0].data().username;
            const loggedInUsername = currentUser.displayName || currentUser.email;

            // Check if there's already a pending request
            const pendingAcceptDocRef = doc(db, 'pendingaccept', targetUserId);
            const pendingAcceptDocSnap = await getDoc(pendingAcceptDocRef);

            if (pendingAcceptDocSnap.exists() && pendingAcceptDocSnap.data().origin.includes(currentUser.uid)) {
                setRequestPending(true);
                setShowPopup(true);
                setLoading(false);
                return;
            }

            // Create a new document in the 'rel' collection
            const newRelDocRef = doc(collection(db, 'rel'));
            await setDoc(newRelDocRef, {
                ispending: true,
                name: nickname,
                origin: currentUser.uid,
                status: parseInt(relationshipStatus),
                target: targetUserId,
            });

            // Check if the relationships document exists for the current user
            const relationshipDocRef = doc(db, 'relationships', currentUser.uid);
            const relationshipDocSnap = await getDoc(relationshipDocRef);

            if (!relationshipDocSnap.exists()) {
                // Create the document if it does not exist
                await setDoc(relationshipDocRef, {
                    relid: []
                });
            }

            // Add the relationship ID to the 'relationships' collection
            await updateDoc(relationshipDocRef, {
                relid: arrayUnion(newRelDocRef.id),
            });

            // Update or create the document in the 'pendingaccept' collection
            await updateDoc(pendingAcceptDocRef, {
                origin: arrayUnion(currentUser.uid),
            }).catch(async (error) => {
                if (error.code === 'not-found') {
                    await setDoc(pendingAcceptDocRef, {
                        origin: [currentUser.uid],
                    });
                } else {
                    throw error;
                }
            });

            // Send email notification
            await fetch(`${domain}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    targetUserId,
                    loggedInUsername,
                    targetUsername,
                }),
            });

            setSuccess(t('add_someone.success.invitation_sent'));
            setShowPopup(true);
        } catch (error) {
            console.error(t('add_someone.error.verifying_username'), error);
            setError(t('add_someone.error.verification_failed'));
        } finally {
            setLoading(false);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        navigate('/addedpeople');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36 md:pb-14">
                <h1 className="text-4xl mb-5">{t('add_someone.title')}</h1>
                <p className="text-xl mb-10 text-left">{t('add_someone.description')}</p>
                <main className="flex justify-center items-center flex-col text-lef">
                    <form onSubmit={handleSubmit} className="w-full max-w-lg flex flex-col items-start bg-white p-8 rounded-lg shadow">
                        <div className="w-full mb-4 text-left">
                            <label htmlFor="username" className="block text-left text-gray-700 mb-2">{t('add_someone.username')}</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder={t('add_someone.username_placeholder')}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>
                        <div className="w-full mb-4">
                            <label htmlFor="relationshipStatus" className="block text-left text-gray-700 mb-2">{t('add_someone.relationship_status')}</label>
                            <select
                                id="relationshipStatus"
                                value={relationshipStatus}
                                onChange={(e) => setRelationshipStatus(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded"
                            >
                                <option value="">{t('add_someone.select_status')}</option>
                                {statusMap.map(status => (
                                    <option key={status.value} value={status.value}>{status.label}</option>
                                ))}
                            </select>
                            <p className="text-sm text-gray-600 mt-1 text-left">{t('add_someone.info_note')}</p>
                        </div>
                        <div className="w-full mb-4">
                            <label htmlFor="nickname" className="block text-left text-gray-700 mb-2">{t('add_someone.nickname')}</label>
                            <input
                                type="text"
                                id="nickname"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                                placeholder={t('add_someone.nickname_placeholder')}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>
                        {error && <p className="text-red-500 mb-4">{error}</p>}
                        {success && <p className="text-green-500 mb-4">{success}</p>}
                        <button className="w-full py-3 bg-[#ff5722] text-white font-bold text-lg rounded mt-4 hover:bg-[#ff5722]" type="submit" disabled={loading}>
                            {loading ? t('add_someone.sending') : t('add_someone.add')}
                        </button>
                    </form>
                </main>
            </div>
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg text-center shadow-lg">
                        {requestPending ? (
                            <>
                                <h2 className="text-2xl mb-5">{t('add_someone.request_pending_title')}</h2>
                                <p className="text-xl mb-5">{t('add_someone.request_pending_message')}</p>
                                <button onClick={handleClosePopup} className="px-5 py-2 bg-blue-500 text-white rounded-lg">OK</button>
                            </>
                        ) : (
                            <>
                                <h2 className="text-2xl mb-5">{t('add_someone.request_sent_title')}</h2>
                                <p className="text-xl mb-5">{t('add_someone.request_sent_message', { nickname })}</p>
                                <button onClick={handleClosePopup} className="px-5 py-2 bg-blue-500 text-white rounded-lg">OK</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddSomeonePage;
