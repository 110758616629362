import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useApprove } from './ApproveContext';
import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc, arrayRemove, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';

function ConnectedPeoplePage() {
    const { currentUser } = useAuth();
    const { saveApprovedPerson } = useApprove();
    const [connectedPeople, setConnectedPeople] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchConnectedPeople = async () => {
            if (currentUser) {
                const relationshipDoc = doc(db, 'relationships', currentUser.uid);
                const docSnap = await getDoc(relationshipDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const connected = data.connected || [];
                    const connectedWithUsernames = await Promise.all(
                        connected.map(async (personId) => {
                            const personDoc = await getDoc(doc(db, 'ep', personId));
                            if (personDoc.exists()) {
                                return {
                                    id: personId,
                                    username: personDoc.data().username,
                                    isPending: false,
                                };
                            }
                            return null;
                        })
                    );
                    setConnectedPeople(connectedWithUsernames.filter(person => person !== null));
                }
            }
        };

        const fetchPendingRequests = async () => {
            if (currentUser) {
                const pendingAcceptDoc = doc(db, 'pendingaccept', currentUser.uid);
                const docSnap = await getDoc(pendingAcceptDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const origins = data.origin || [];
                    const pendingWithUsernames = await Promise.all(
                        origins.map(async (originId) => {
                            const originDoc = await getDoc(doc(db, 'ep', originId));
                            if (originDoc.exists()) {
                                return {
                                    id: originId,
                                    username: originDoc.data().username,
                                    isPending: true,
                                };
                            }
                            return null;
                        })
                    );
                    setPendingRequests(pendingWithUsernames.filter(request => request !== null));
                }
            }
        };

        fetchConnectedPeople();
        fetchPendingRequests();
    }, [currentUser]);

    const handleDelete = async () => {
        if (currentUser && selectedPerson) {
            const relationshipDoc = doc(db, 'relationships', currentUser.uid);
            await updateDoc(relationshipDoc, {
                connected: arrayRemove(selectedPerson.id)
            });

            const relCollection = collection(db, 'rel');
            const q = query(relCollection, where('origin', '==', selectedPerson.id), where('target', '==', currentUser.uid));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (relDoc) => {
                await updateDoc(relDoc.ref, {
                    isblocked: true
                });
            });

            setConnectedPeople(connectedPeople.filter(person => person.id !== selectedPerson.id));
            setShowPopup(false);
            setSelectedPerson(null);
        }
    };

    const handleDeleteClick = (person) => {
        setSelectedPerson(person);
        setShowPopup(true);
    };

    const handleViewAndConfirm = (person) => {
        saveApprovedPerson(person);
        navigate('/approve-connection');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5 text-center">{t('connected_people.title')}</h1>
                <p className="text-xl mb-10 text-left">{t('connected_people.description')}</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    {pendingRequests.concat(connectedPeople).map((person) => (
                        <div key={person.id} className="bg-white p-5 rounded-lg shadow text-center flex flex-col items-center">
                            <h2 className="text-2xl mb-5">{person.username}</h2>
                            <button
                                className={`px-5 py-2 rounded-lg ${person.isPending ? 'bg-[#ff5722]' : 'bg-blue-500'} text-white`}
                                onClick={() => person.isPending ? handleViewAndConfirm(person) : handleDeleteClick(person)}
                            >
                                {person.isPending ? t('connected_people.view_and_confirm') : t('connected_people.delete')}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg text-center shadow-lg">
                        <h2 className="text-2xl mb-5">{t('connected_people.confirm_delete')}</h2>
                        <p className="text-xl mb-5 textl">{t('connected_people.confirm_delete_message', { username: selectedPerson.username })}</p>
                        <div className="flex justify-center gap-5">
                            <button onClick={handleDelete} className="px-5 py-2 bg-blue-500 text-white rounded-lg">{t('confirm')}</button>
                            <button onClick={() => setShowPopup(false)} className="px-5 py-2 bg-red-500 text-white rounded-lg">{t('cancel')}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ConnectedPeoplePage;
