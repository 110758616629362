import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './stripe';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Header2 from './components/Header2';
import Footer from './components/Footer';
import './css/PurchasePage.css';

const domain = process.env.REACT_APP_DOMAIN;

async function fetchTaxAmount(packageId, country, postal_code, price) {
    try {
        const response = await fetch('${domain}/api/calculate-tax', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ packageId, country, postal_code, price })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch tax amount');
        }

        const data = await response.json();
        //console.log('Tax amount fetched from server:', data);
        return data.taxAmount;
    } catch (error) {
        //console.error('Error fetching tax amount:', error);
        return 0;
    }
}

function PurchaseForm({ packageId, packageDetails, onConfirm }) {
    const [name, setName] = useState('');
    const [address, setAddress] = useState({ line1: '', line2: '', city: '', state: '', postal_code: '', country: '' });

    const handleAddressChange = (field, value) => {
        setAddress((prev) => ({ ...prev, [field]: value }));
    };

    const handleConfirm = async () => {
        if (address.country && address.postal_code) {
            //console.log('Confirming address:', address);
            const taxAmount = await fetchTaxAmount(packageId, address.country, address.postal_code, packageDetails.priceusd);
            onConfirm({ name, address, taxAmount });
        } else {
            alert('Please fill in the country and postal code.');
        }
    };

    return (
        <div className="purchase-form">
            <div className="form-section">
                <h2>Billing Information</h2>
                <label>
                    Name
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </label>
                <label>
                    Address Line 1
                    <input type="text" value={address.line1} onChange={(e) => handleAddressChange('line1', e.target.value)} required />
                </label>
                <label>
                    Address Line 2
                    <input type="text" value={address.line2} onChange={(e) => handleAddressChange('line2', e.target.value)} />
                </label>
                <label>
                    City
                    <input type="text" value={address.city} onChange={(e) => handleAddressChange('city', e.target.value)} required />
                </label>
                <label>
                    State
                    <input type="text" value={address.state} onChange={(e) => handleAddressChange('state', e.target.value)} required />
                </label>
                <label>
                    Postal Code
                    <input type="text" value={address.postal_code} onChange={(e) => handleAddressChange('postal_code', e.target.value)} required />
                </label>
                <label>
                    Country
                    <input type="text" value={address.country} onChange={(e) => handleAddressChange('country', e.target.value)} required />
                </label>
                <button type="button" onClick={handleConfirm}>Confirm</button>
            </div>
        </div>
    );
}

function PurchasePage() {
    const { packageId } = useParams();
    const stripe = useStripe();
    const elements = useElements();
    const [packageDetails, setPackageDetails] = useState(null);
    const [tax, setTax] = useState(0);
    const [showPayment, setShowPayment] = useState(false);

    useEffect(() => {
        const fetchPackageDetails = async () => {
            const packageDoc = doc(db, 'packages', packageId);
            const packageSnap = await getDoc(packageDoc);
            if (packageSnap.exists()) {
                setPackageDetails(packageSnap.data());
            }
        };

        fetchPackageDetails();
    }, [packageId]);

    const handleConfirm = ({ name, address, taxAmount }) => {
        //console.log('Confirming with tax amount:', taxAmount);
        setTax(taxAmount);
        setShowPayment(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        // Here, you would typically send the form data to your backend server to create a payment intent and then confirm the payment.
    };

    return (
        <Elements stripe={stripePromise}>
            <div className="purchase-page">
                <Header2 />
                <div className="content">
                    <div className="left-half">
                        {packageDetails && (
                            <PurchaseForm packageId={packageId} packageDetails={packageDetails} onConfirm={handleConfirm} />
                        )}
                    </div>
                    <div className="right-half">
                        {packageDetails && (
                            <>
                                <div className="product-info">
                                    <h2>Product Information</h2>
                                    <p><strong>Product Name:</strong> {packageDetails.name}</p>
                                    <p><strong>Number of Credits:</strong> {packageDetails.numberofcredits}</p>
                                    <p><strong>Product Price:</strong> ${packageDetails.priceusd}</p>
                                    <p><strong>Tax:</strong> ${tax}</p>
                                    <p><strong>Total Price:</strong> ${packageDetails.priceusd + tax}</p>
                                </div>
                                {showPayment && (
                                    <form onSubmit={handleSubmit} className="payment-form">
                                        <h2>Payment Information</h2>
                                        <CardElement />
                                        <button type="submit" disabled={!stripe}>Submit Payment</button>
                                    </form>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </Elements>
    );
}

export default PurchasePage;
