import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useCredits } from '../CreditsContext';
import { useTranslation } from 'react-i18next';

function Header2() {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();
    const { credits } = useCredits();
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (err) {
            console.error("Failed to log out:", err);
        }
    };

    const toggleMenu = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            closeMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isTestAlsamindPage = location.pathname === '/test-alsamind';

    return (
        <div className='fixed w-full z-50'>
            <div className="w-full text-center p-2 bg-[#d7f3f8] border-b border-[#9ec4cb] text-xs font-bold text-[#58637d]">
                {t('header2.beta_warning')}
            </div>

            <div className='w-full px-10 md:px-20 shadow-md bg-white'>
                <header className="header2 w-full flex justify-between items-center relative" ref={menuRef}>
                    <div className="logo2 w-10 md:w-20 cursor-pointer" onClick={() => navigate('/')}>
                        <img src="/alsamind-logo.png" alt="AlsaMind Logo" />
                    </div>
                    {!isTestAlsamindPage && (
                        <div className='hidden md:block'>
                            <a href="/dashboard" className='me-4'>{t('header2.dashboard')}</a>
                            <a href="/mypersonality" className='me-4'>{t('header2.mypersonality')}</a>
                            <a href="/myrelationships" className='me-4'>{t('header2.myrelationships')}</a>
                            <a href="/myaccount" className='me-4'>{t('header2.my_account')}</a>
                            <a href="/mycredits" className='me-4'>{t('header2.add_credits')}</a>
                        </div>
                    )}
                    <nav className={`w-full h-full flex justify-center items-center fixed top-0 left-0 z-50 bg-black text-white text-3xl transition-transform duration-300 ${isMenuOpen ? 'transform translate-x-0' : 'transform -translate-x-full'}`}>
                        <ul className="space-y-4 text-center gap-4">
                            {!isTestAlsamindPage && (
                                <>
                                    <li className='my-5'><a href="/dashboard" className='text-2xl' onClick={closeMenu}>{t('header2.dashboard')}</a></li>
                                    <li className='my-5'><a href="/mypersonality" className='text-2xl' onClick={closeMenu}>{t('header2.mypersonality')}</a></li>
                                    <li className='my-5'><a href="/myrelationships" className='text-2xl' onClick={closeMenu}>{t('header2.myrelationships')}</a></li>
                                    <li className='my-5'><a href="/myaccount" className='text-2xl' onClick={closeMenu}>{t('header2.my_account')}</a></li>
                                    <li className='my-5'><a href="/mycredits" className='text-2xl' onClick={closeMenu}>{t('header2.add_credits')}</a></li>
                                </>
                            )}
                            <li className="md:hidden my-5"><button className="bg-[#ff5722] py-2 px-6 text-4xl rounded-md hover:bg-[#d3562f]" onClick={handleLogout}>{t('header2.logout')}</button></li>
                        </ul>
                        {isMenuOpen && <button className="text-white absolute top-5 right-5 z-50" onClick={closeMenu}>
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className='w-6 h-6'>
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path fill="currentColor" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"></path>
                                </g>
                            </svg>
                        </button>}
                    </nav>
                    <div className="flex justify-end gap-4 items-center">
                        <span className="font-bold">{t('header2.credits')}: {credits}</span>
                        <button className="login-button desktop-only hidden md:inline-block bg-[#ff5722] text-white py-2 px-4 rounded-md hover:bg-[#d3562f]" onClick={handleLogout}>
                            {t('header2.logout')}
                        </button>
                        <button className="md:hidden flex items-center text-gray-500 text-xl" onClick={toggleMenu}>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-6 h-6'>
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                    {isMenuOpen && <div className="overlay2 fixed inset-0 bg-black opacity-50" onClick={closeMenu}></div>}
                </header>
            </div>
        </div>
    );
}

export default Header2;
