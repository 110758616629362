import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';

function WorkWithCoFounderPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleContact = () => {
        // Redirect to a contact form or scheduling page
        navigate('/contact');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-24">
                <h1 className="text-5xl font-bold mb-6 text-gray-800">{t('work_with_cofounder.title')}</h1>
                <p className="text-xl mb-8 text-gray-700">
                    {t('work_with_cofounder.description')}
                </p>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">Mike Beck</h2>
                        <p className="text-lg text-gray-600 mb-6">
                            {t('work_with_cofounder.mike_description')}
                        </p>
                        <button
                            onClick={handleContact}
                            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-medium"
                        >
                            {t('work_with_cofounder.contact_button')}
                        </button>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">Lu Tan</h2>
                        <p className="text-lg text-gray-600 mb-6">
                            {t('work_with_cofounder.lu_description')}
                        </p>
                        <button
                            onClick={handleContact}
                            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-medium"
                        >
                            {t('work_with_cofounder.contact_button')}
                        </button>
                    </div>
                </div>
                <div className="mt-12 bg-white p-8 rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">{t('work_with_cofounder.benefits_title')}</h3>
                    <ul className="text-lg text-gray-700 list-disc list-inside space-y-2">
                        <li>{t('work_with_cofounder.benefit_1')}</li>
                        <li>{t('work_with_cofounder.benefit_2')}</li>
                        <li>{t('work_with_cofounder.benefit_3')}</li>
                        <li>{t('work_with_cofounder.benefit_4')}</li>
                        <li>{t('work_with_cofounder.benefit_5')}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default WorkWithCoFounderPage;
