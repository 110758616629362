import React, { createContext, useState, useContext } from 'react';

const UPNContext = createContext({
    upn: null,
    setUPN: () => { }
});

export const UPNProvider = ({ children }) => {
    const [upn, setUPN] = useState(null);

    return (
        <UPNContext.Provider value={{ upn, setUPN }}>
            {children}
        </UPNContext.Provider>
    );
};

export const useUPN = () => useContext(UPNContext);

export default UPNContext;
