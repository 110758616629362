// THIS COMPONENT IS TO SAVE WHO THE PERSON IS CURRENTLY VIEWING BY SAVING THE PERSON ID TO THE RELATIONSHIP CONTEXT
import React, { createContext, useState, useContext, useEffect } from 'react';

const RelationshipContext = createContext();

export const useRelationship = () => useContext(RelationshipContext);

export const RelationshipProvider = ({ children }) => {
    const [relationshipData, setRelationshipData] = useState(() => {
        const savedData = localStorage.getItem('relationshipData');
        return savedData ? JSON.parse(savedData) : {};
    });
    const [currentPersonId, setCurrentPersonId] = useState(() => {
        return localStorage.getItem('currentPersonId') || null;
    });

    useEffect(() => {
        localStorage.setItem('relationshipData', JSON.stringify(relationshipData));
    }, [relationshipData]);

    useEffect(() => {
        localStorage.setItem('currentPersonId', currentPersonId);
    }, [currentPersonId]);

    const updateRelationshipData = (relId, data) => {
        setRelationshipData((prevData) => ({
            ...prevData,
            [relId]: data,
        }));
    };

    return (
        <RelationshipContext.Provider value={{ relationshipData, updateRelationshipData, currentPersonId, setCurrentPersonId }}>
            {children}
        </RelationshipContext.Provider>
    );
};
