import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useRelationship } from './RelationshipContext';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';

function ManualListPage() {
    const { currentUser } = useAuth();
    const { updateRelationshipData, setCurrentPersonId } = useRelationship();
    const [manualPeople, setManualPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const statusMap = {
        1: t('status.love'),
        2: t('status.friend'),
        3: t('status.parent'),
        4: t('status.child'),
        5: t('status.family'),
        6: t('status.boss'),
        7: t('status.employee'),
        8: t('status.colleague'),
    };

    useEffect(() => {
        const fetchManualPeople = async () => {
            if (currentUser) {
                const manualepDoc = doc(db, 'manualep', currentUser.uid);
                const docSnap = await getDoc(manualepDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const relIds = data.relid || [];
                    const peopleData = await Promise.all(
                        relIds.map(async (relId) => {
                            const relDocRef = doc(db, 'rel', relId);
                            const relDoc = await getDoc(relDocRef);
                            if (relDoc.exists()) {
                                const relData = relDoc.data();
                                updateRelationshipData(relId, relData); // Update relationship data in context
                                return {
                                    id: relId,
                                    status: statusMap[relData.status],
                                    name: relData.name, // Using the name directly from rel document
                                };
                            }
                            return null;
                        })
                    );
                    setManualPeople(peopleData.filter(person => person !== null));
                }
                setLoading(false);
            }
        };

        fetchManualPeople();
    }, [currentUser, updateRelationshipData, statusMap]);

    const handleOpenPerson = (personId, e) => {
        e.stopPropagation();
        setCurrentPersonId(personId);
        navigate(`/manualperson`);
    };

    // ADD NEW PERSON BUTTON
    const handleAddNewPerson = (e) => {
        e.stopPropagation(); // Prevent the event from bubbling up
        navigate('/addmanualperson');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5 text-center">{t('manual_list.title')}</h1>
                <p className="text-xl mb-10 text-left">{t('manual_list.description')}</p>
                {loading ? (
                    <p>{t('manual_list.loading')}</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                        <div className="flex flex-col items-center justify-center bg-gray-100 border-2 border-dashed border-gray-300 cursor-pointer transition hover:bg-gray-200 p-5 rounded-lg" onClick={handleAddNewPerson}>
                            <div className="text-4xl font-bold text-gray-500 mb-3">+</div>
                            <button className="bg-[#ff5722] text-white px-5 py-2 rounded-lg hover:bg-[#ff5722]">{t('manual_list.add_button')}</button>
                        </div>
                        {manualPeople.map((person) => (
                            <div key={person.id} className="bg-white p-5 rounded-lg shadow text-center flex flex-col items-center">
                                <h2 className="text-2xl mb-5">{person.name}</h2>
                                <p className="text-lg mb-5">{t('manual_list.status')}: {person.status}</p>
                                <button className="bg-blue-500 text-white px-5 py-2 rounded-lg" onClick={(e) => handleOpenPerson(person.id, e)}>{t('manual_list.open_button')}</button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ManualListPage;
