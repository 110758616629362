import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useUPN } from './UPNContext';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, setDoc, getDoc, updateDoc, getDocs, query, collection, where } from 'firebase/firestore';
import { getAuth, deleteUser } from 'firebase/auth';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from './LanguageContext';

const domain = process.env.REACT_APP_DOMAIN;
const libraries = ['places'];

function TestAlsaMindPage() {
    const { currentUser } = useAuth();
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [birthDate, setBirthDate] = useState('');
    const [birthTime, setBirthTime] = useState('');
    const [location, setLocation] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: 48.5276, lng: 7.2137 });
    const [isDST, setIsDST] = useState(false);
    const [gender, setGender] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [ageRestricted, setAgeRestricted] = useState(false);
    const [missingFields, setMissingFields] = useState([]);
    const context = useUPN();
    const setUPN = context ? context.setUPN : () => { };
    const navigate = useNavigate();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    useEffect(() => {
        const checkUPN = async () => {
            if (currentUser) {
                const userDocRef = doc(db, 'ep', currentUser.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    if (userData.upn) {
                        navigate('/dashboard');
                    }
                }
            }
        };

        checkUPN();
    }, [currentUser, navigate]);

    const onLoad = useCallback((autocomplete) => {
        if (!autocomplete) {
            console.error('Autocomplete is not loaded properly');
            return;
        }
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                alert(t('test_alsamind.alert_no_details') + place.name);
                return;
            }
            setLocation(place.formatted_address);
            setCoordinates({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
        });
    }, [t]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
            age--;
        }
        return age;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const missing = [];
        if (!birthDate) missing.push('birthDate');
        if (!birthTime) missing.push('birthTime');
        if (!gender) missing.push('gender');
        if (!username) missing.push('username');
        if (!location) missing.push('location');

        setMissingFields(missing);

        if (missing.length > 0) {
            alert(t('test_alsamind.alert_fill_all_fields'));
            return;
        }

        if (calculateAge(birthDate) < 18) {
            await handleUserDeletion();
            navigate('/age-restriction');
            return;
        }

        if (coordinates.lat < 0) {
            await handleUserDeletion();
            navigate('/location-restriction');
            return;
        }

        setLoading(true);
        const birthDateTime = new Date(birthDate + 'T' + birthTime);
        const hour = isDST ? birthDateTime.getHours() - 1 : birthDateTime.getHours();
        const params = { y: birthDateTime.getFullYear(), m: birthDateTime.getMonth() + 1, d: birthDateTime.getDate(), h: hour };
        const apiUrl = `${domain}/api/calculate_ep?${new URLSearchParams(params).toString()}`;

        try {
            const response = await fetch(apiUrl);
            if (!response.ok) throw new Error(t('test_alsamind.error_network_response'));
            const data = await response.json();
            const upn = data.result[data.result.length - 1];

            const truncatedLat = Math.floor(coordinates.lat * 10) / 10;
            const truncatedLng = Math.floor(coordinates.lng * 10) / 10;

            const upnQuery = query(collection(db, 'upncheck'), where('upn', '==', upn), where('latitude', '==', truncatedLat), where('longitude', '==', truncatedLng));
            const upnSnapshot = await getDocs(upnQuery);

            if (!upnSnapshot.empty) {
                await handleUserDeletion();
                navigate('/upn-taken');
                return;
            }

            await setDoc(doc(db, 'upncheck', currentUser.uid), { upn, latitude: truncatedLat, longitude: truncatedLng });

            await setDoc(doc(db, 'ep', currentUser.uid), {
                upn,
                gender,
                username,
                credits: 5,
                latitude: coordinates.lat,
                longitude: coordinates.lng
            });

            const personalityParams = new URLSearchParams({ upn, language }).toString();
            const personalityApiUrl = `${domain}/api/personality?${personalityParams}`;
            const personalityResponse = await fetch(personalityApiUrl);
            if (!personalityResponse.ok) throw new Error(t('test_alsamind.error_network_response'));
            const personalityData = await personalityResponse.json();
            const personalityDescription = personalityData.result;

            await setDoc(doc(db, 'personality', currentUser.uid), {
                description: personalityDescription
            });

            navigate('/mypersonality');
        } catch (error) {
            console.error(t('test_alsamind.error_fetching_data'), error);
            alert(t('test_alsamind.error_retrieve_data') + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUserDeletion = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            await deleteUser(user).catch((error) => {
                console.error(t('test_alsamind.error_deleting_user'), error);
            });
        }
    };

    if (loadError) {
        return <div>{t('add_manual_person.error.loading_google_maps')}</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header2 />
            <div className="py-8 pt-36 max-w-[80%] mx-auto">
                <h1 className="text-center text-3xl font-bold mb-6">{t('test_alsamind.title')}</h1>
                <div className="text-center mb-6">
                    <p>{t('test_alsamind.description.line1')}</p>
                    <p>{t('test_alsamind.description.line2')}</p>
                    <p>{t('test_alsamind.description.line3')}</p>
                </div>
                <main className="bg-white p-6 rounded-lg shadow-md md:flex gap-4">
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 w-full md:w-1/2">
                        <div className={`flex flex-col ${missingFields.includes('birthDate') ? 'border border-red-500 p-2' : ''}`}>
                            <label htmlFor="birthDate" className="font-semibold">{t('test_alsamind.birth_date')}</label>
                            <input type="date" id="birthDate" value={birthDate} onChange={e => setBirthDate(e.target.value)} className="mt-1 p-2 border rounded w-full" />
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('birthTime') ? 'border border-red-500 p-2' : ''}`}>
                            <label htmlFor="birthTime" className="font-semibold">{t('test_alsamind.birth_time')}</label>
                            <input type="time" id="birthTime" value={birthTime} onChange={e => setBirthTime(e.target.value)} className="mt-1 p-2 border rounded w-full" />
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" checked={isDST} onChange={e => setIsDST(e.target.checked)} id="dstCheckbox" className="mr-2" />
                            <label htmlFor="dstCheckbox" className="font-semibold">{t('test_alsamind.daylight_saving')}</label>
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('gender') ? 'border border-red-500 p-2' : ''}`}>
                            <label htmlFor="gender" className="font-semibold">{t('test_alsamind.gender')}</label>
                            <select id="gender" value={gender} onChange={e => setGender(e.target.value)} className="mt-1 p-2 border rounded w-full">
                                <option value="">{t('test_alsamind.select_gender')}</option>
                                <option value="male">{t('test_alsamind.male')}</option>
                                <option value="female">{t('test_alsamind.female')}</option>
                            </select>
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('username') ? 'border border-red-500 p-2' : ''}`}>
                            <label htmlFor="username" className="font-semibold">{t('test_alsamind.username')}</label>
                            <input type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} placeholder={t('test_alsamind.username_placeholder')} className="mt-1 p-2 border rounded w-full" />
                        </div>
                        {error && <p className="text-red-500">{error}</p>}

                        <div className={`flex flex-col ${missingFields.includes('location') ? 'border border-red-500 p-2' : ''}`}>
                            <label htmlFor="location" className="font-semibold">{t('test_alsamind.location')}</label>
                            <Autocomplete onLoad={onLoad}>
                                <input className="mt-1 p-2 border rounded w-full" type="text" id="location" value={location} onChange={e => setLocation(e.target.value)} placeholder={t('test_alsamind.location_placeholder')} />
                            </Autocomplete>
                        </div>
                        <button type="submit" disabled={loading} className="w-full bg-blue-500 text-white py-2 rounded mt-4 hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed">
                            {loading ? (
                                <>
                                    <div className="loader border-t-transparent border-4 border-white rounded-full w-5 h-5 mr-3 animate-spin inline-block"></div>
                                    {t('test_alsamind.running_calculations')}
                                </>
                            ) : (
                                t('test_alsamind.submit_button')
                            )}
                        </button>
                    </form>
                    <div className="mt-6 w-full h-60 md:w-1/2 lg:w-1/2 md:min-h-max">
                        {coordinates.lat && (
                            <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={coordinates} zoom={15}>
                                <Marker position={coordinates} />
                            </GoogleMap>
                        )}
                    </div>
                </main>
            </div>
            {ageRestricted && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-md text-center">
                        <h2 className="text-2xl font-bold">{t('test_alsamind.restricted')}</h2>
                        <p className="mt-4">{t('test_alsamind.age_restriction_message')}</p>
                        <p className="mt-2">{t('test_alsamind.account_deleted_message')}</p>
                        <button onClick={() => navigate('/age-restriction')} className="mt-6 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                            {t('test_alsamind.close_button')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TestAlsaMindPage;
