import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Header() {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="fixed top-0 w-full flex justify-between items-center px-10 bg-gray-100 shadow-md z-50">
            <div className="w-10 md:w-20 cursor-pointer" onClick={() => navigate('/')}>
                <img src="/alsamind-logo.png" alt="AlsaMind Logo" />
            </div>
            <div className='flex justify-between items-center'>
                <nav className={`fixed top-0 right-0 w-full h-full bg-black flex flex-col justify-center items-center z-40 transition-transform transform ${menuOpen ? 'scale-100' : 'scale-0'} md:static md:flex-row md:bg-transparent md:scale-100`}>
                    <ul className="flex flex-col gap-10 md:flex-row md:gap-5">
                        <li><a href="/#about" className="text-white md:text-black text-2xl md:text-base">{t('header.what_it_does')}</a></li>
                        <li><a href="/#services" className="text-white md:text-black text-2xl md:text-base">{t('header.how_it_works')}</a></li>
                        <li><a href="/#contact" className="text-white md:text-black text-2xl md:text-base">{t('header.what_it_can_tell_you')}</a></li>
                        <li className="block md:hidden">
                            <button className="login-button w-full md:hidden bg-orange-600 text-white py-2 px-4 rounded" onClick={() => navigate('/login')}>
                                {t('header.login')}
                            </button>
                        </li>

                    </ul>
                    <button className="absolute top-5 right-5 text-white text-3xl md:hidden" onClick={toggleMenu}>&times;</button>
                </nav>
            </div>
            <div className="flex gap-5 items-center">
                <button className="login-button w-auto hidden md:block bg-orange-600 text-white py-2 px-4 rounded" onClick={() => navigate('/login')}>
                    {t('header.login')}
                </button>
                <button className="menu-toggle block md:hidden text-3xl text-black" onClick={toggleMenu}>&#9776;</button>
            </div>
            {menuOpen && <div className="fixed inset-0 bg-black opacity-50 z-30" onClick={toggleMenu}></div>}
        </header>
    );
}

export default Header;
