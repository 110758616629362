import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MyRelationshipsPage() {
    const { currentUser } = useAuth();
    const [connectedCount, setConnectedCount] = useState(0);
    const [relCount, setRelCount] = useState(0);
    const [manualCount, setManualCount] = useState(0);
    const [pendingRequestsCount, setPendingRequestsCount] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchRelationships = async () => {
            if (currentUser) {
                const relationshipDoc = doc(db, 'relationships', currentUser.uid);
                const docSnap = await getDoc(relationshipDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setConnectedCount(data.connected ? data.connected.length : 0);
                    setRelCount(data.relid ? data.relid.length : 0);
                }
            }
        };

        const fetchManualEntries = async () => {
            if (currentUser) {
                const manualDoc = doc(db, 'manualep', currentUser.uid);
                const docSnap = await getDoc(manualDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setManualCount(data.relid ? data.relid.length : 0);
                }
            }
        };

        const fetchPendingRequests = async () => {
            if (currentUser) {
                const pendingAcceptDoc = doc(db, 'pendingaccept', currentUser.uid);
                const docSnap = await getDoc(pendingAcceptDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setPendingRequestsCount(data.origin ? data.origin.length : 0);
                }
            }
        };

        fetchRelationships();
        fetchManualEntries();
        fetchPendingRequests();
    }, [currentUser]);

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5">{t('myrelationships.title')}</h1>
                <p className="text-xl mb-10 text-left">{t('myrelationships.description')}</p>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('myrelationships.people_you_added')}</h2>
                        <p className="text-lg mb-5">{t('myrelationships.people_count', { count: relCount })}</p>
                        <button className="px-5 py-2 bg-blue-500 text-white rounded-lg" onClick={() => navigate('/addedpeople')}>
                            {t('myrelationships.view_button')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('myrelationships.people_who_added_you')}</h2>
                        <p className="text-lg mb-5">{t('myrelationships.people_count', { count: connectedCount })}</p>
                        {pendingRequestsCount > 0 && (
                            <p className="text-red-500 font-bold">{t('myrelationships.pending_requests', { count: pendingRequestsCount })}</p>
                        )}
                        <button className="px-5 py-2 bg-blue-500 text-white rounded-lg mt-5" onClick={() => navigate('/connectedpeople')}>
                            {t('myrelationships.view_button')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('myrelationships.manually_added_people')}</h2>
                        <p className="text-lg mb-5">{t('myrelationships.people_count', { count: manualCount })}</p>
                        <button className="px-5 py-2 bg-blue-500 text-white rounded-lg" onClick={() => navigate('/manualpeople')}>
                            {t('myrelationships.view_button')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyRelationshipsPage;
