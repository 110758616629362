import React from 'react';
import './BlueSection.css';
import { useTranslation } from 'react-i18next';

function BlueSection() {
    const { t } = useTranslation();

    return (
        <section className="blue-section">
            <h2>{t('blue_section.title')}</h2>
        </section>
    );
}

export default BlueSection;
