// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-section {
    display: flex;
    padding: 80px 20px;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    gap: 40px;
}

.video-container {
    flex: 1 1;
    margin-right: 20px;
    border-radius: 20px;
    overflow: hidden;
    /* Ensure video doesn't overflow the container */
}

.text-container {
    flex: 1 1;
    text-align: left;
}

/* Media query for tablets and smaller devices */
@media (max-width: 768px) {
    .video-section {
        flex-direction: column;
        padding: 40px 20px;
        /* Adjust padding for smaller screens */
    }

    .video-container {
        margin-right: 0;
        margin-bottom: 20px;
        /* Add space between video and text */
    }

    .text-container {
        text-align: center;
        /* Center text on smaller screens */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/VideoSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,UAAU;IACV,cAAc;IACd,SAAS;AACb;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;AACpD;;AAEA;IACI,SAAO;IACP,gBAAgB;AACpB;;AAEA,gDAAgD;AAChD;IACI;QACI,sBAAsB;QACtB,kBAAkB;QAClB,uCAAuC;IAC3C;;IAEA;QACI,eAAe;QACf,mBAAmB;QACnB,qCAAqC;IACzC;;IAEA;QACI,kBAAkB;QAClB,mCAAmC;IACvC;AACJ","sourcesContent":[".video-section {\n    display: flex;\n    padding: 80px 20px;\n    align-items: center;\n    flex-wrap: wrap;\n    width: 80%;\n    margin: 0 auto;\n    gap: 40px;\n}\n\n.video-container {\n    flex: 1;\n    margin-right: 20px;\n    border-radius: 20px;\n    overflow: hidden;\n    /* Ensure video doesn't overflow the container */\n}\n\n.text-container {\n    flex: 1;\n    text-align: left;\n}\n\n/* Media query for tablets and smaller devices */\n@media (max-width: 768px) {\n    .video-section {\n        flex-direction: column;\n        padding: 40px 20px;\n        /* Adjust padding for smaller screens */\n    }\n\n    .video-container {\n        margin-right: 0;\n        margin-bottom: 20px;\n        /* Add space between video and text */\n    }\n\n    .text-container {\n        text-align: center;\n        /* Center text on smaller screens */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
