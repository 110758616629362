// THIS PAGE IS FOR WHEN A USER UNDER 18 TRIES TO ACCESS THE APP
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header1 from './components/Header';
import './css/AgeRestrictionPage.css';
import { useTranslation } from 'react-i18next';

function AgeRestrictionPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        navigate('/');
    };

    return (
        <div>
            <Header1 />
            <div className="content">
                <h2>{t('agerestriction.restricted')}</h2>
                <p>{t('agerestriction.ageMessage')}</p>
                <p>{t('agerestriction.deletedMessage')}</p>
                <button className='okbutton' onClick={handleClose}>{t('agerestriction.okButton')}</button>
            </div>
        </div>
    );
}

export default AgeRestrictionPage;
