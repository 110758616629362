import React, { useState, useEffect } from 'react';
import { useApprove } from './ApproveContext';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, setDoc, updateDoc, arrayUnion, arrayRemove, collection, query, where, getDocs } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ApproveConnectionPage() {
    const { approvedPerson } = useApprove();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [allowPersonality, setAllowPersonality] = useState(true);
    const [allowPastFuture, setAllowPastFuture] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (currentUser && approvedPerson) {
            console.log('Logged-in user ID:', currentUser.uid);
            console.log('Target user ID:', approvedPerson.id);
        }
    }, [currentUser, approvedPerson]);

    if (!approvedPerson) {
        return <p>{t('approve_connection.loading')}</p>;
    }

    const handleTogglePersonality = () => {
        setAllowPersonality(!allowPersonality);
        console.log('Personality toggle:', !allowPersonality);
    };

    const handleTogglePastFuture = () => {
        setAllowPastFuture(!allowPastFuture);
        console.log('Past/Future toggle:', !allowPastFuture);
    };

    const handleApprove = async () => {
        if (!currentUser) return;

        const userId = currentUser.uid;
        const targetUserId = approvedPerson.id;

        try {
            const relationshipDocRef = doc(db, 'relationships', userId);
            await setDoc(relationshipDocRef, {
                connected: arrayUnion(targetUserId),
            }, { merge: true });

            const relCollection = collection(db, 'rel');
            const q = query(relCollection, where('origin', '==', targetUserId), where('target', '==', userId));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (relDoc) => {
                await updateDoc(relDoc.ref, {
                    personalityallowed: allowPersonality,
                    timetravelallowed: allowPastFuture,
                    ispending: false,
                });
            });

            const pendingAcceptDocRef = doc(db, 'pendingaccept', userId);
            await updateDoc(pendingAcceptDocRef, {
                origin: arrayRemove(targetUserId)
            });

            navigate('/connectedpeople');
        } catch (error) {
            console.error('Error approving connection:', error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex flex-1 flex-col text-left max-w-4xl mx-auto mt-36">
                <div className="mb-5 p-6 md:p-8 bg-white rounded-3xl">
                    <h1 className="text-4xl text-center mb-20">{t('approve_connection.title', { username: approvedPerson.username })}</h1>

                    <div className="flex items-center justify-between mb-5">
                        <div className="max-w-[70%]">
                            <h2 className="text-2xl mt-5">{t('approve_connection.relationship_calculation_title', { username: approvedPerson.username })}</h2>
                            <p className="text-xl mb-2">{t('approve_connection.relationship_calculation_description', { username: approvedPerson.username })}</p>
                        </div>
                        <label className="relative inline-block w-16 h-8">
                            <input type="checkbox" checked disabled className="opacity-0 w-0 h-0" />
                            <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full before:absolute before:content-[''] before:h-6 before:w-6 before:left-1 before:bottom-1 before:rounded-full before:transition-transform before:duration-300 bg-blue-500 before:bg-white before:translate-x-full`}></span>
                        </label>
                    </div>

                    <div className="flex items-center justify-between mb-5">
                        <div className="max-w-[70%]">
                            <h2 className="text-2xl mt-5">{t('approve_connection.personality_access_title', { username: approvedPerson.username })}</h2>
                            <p className="text-xl mb-2">{t('approve_connection.personality_access_description', { username: approvedPerson.username })}</p>
                        </div>
                        <label className="relative inline-block w-16 h-8">
                            <input type="checkbox" checked={allowPersonality} onChange={handleTogglePersonality} className="opacity-0 w-0 h-0" />
                            <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full before:absolute before:content-[''] before:h-6 before:w-6 before:left-1 before:bottom-1 before:rounded-full before:transition-transform before:duration-300 ${allowPersonality ? 'bg-blue-500 before:translate-x-full before:bg-white' : 'bg-gray-400 before:bg-white'}`}></span>
                        </label>
                    </div>

                    <div className="flex items-center justify-between mb-5">
                        <div className="max-w-[70%]">
                            <h2 className="text-2xl mt-5">{t('approve_connection.past_future_access_title', { username: approvedPerson.username })}</h2>
                            <p className="text-xl mb-2">{t('approve_connection.past_future_access_description', { username: approvedPerson.username })}</p>
                        </div>
                        <label className="relative inline-block w-16 h-8">
                            <input type="checkbox" checked={allowPastFuture} onChange={handleTogglePastFuture} className="opacity-0 w-0 h-0" />
                            <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full before:absolute before:content-[''] before:h-6 before:w-6 before:left-1 before:bottom-1 before:rounded-full before:transition-transform before:duration-300 ${allowPastFuture ? 'bg-blue-500 before:translate-x-full before:bg-white' : 'bg-gray-400 before:bg-white'}`}></span>
                        </label>
                    </div>

                    <div className="w-full flex flex-col items-center gap-5 mt-10">
                        <button className="w-48 py-3 bg-blue-600 text-white font-bold text-lg rounded-md hover:bg-blue-700" onClick={handleApprove}>
                            {t('approve_connection.approve_button', { username: approvedPerson.username })}
                        </button>
                        <button className="w-48 py-3 bg-white border border-blue-600 text-red-600 font-bold text-lg rounded-md hover:bg-gray-200">
                            {t('approve_connection.reject_button')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApproveConnectionPage;
