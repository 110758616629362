//THIS CMPONENT HANDLES THE NUMBER OF CREDITS SO THAT WE DON'T CONSTANTLY READ IT FROM THE DATABASE UNLESS THEER'S A CHANGE
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const CreditsContext = createContext();

export const useCredits = () => {
    return useContext(CreditsContext);
};

export const CreditsProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [credits, setCredits] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCredits = async () => {
            if (currentUser) {
                try {
                    const userDocRef = doc(db, 'ep', currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        setCredits(userData.credits || 0);
                    }
                } catch (error) {
                    console.error('Error fetching credits:', error);
                }
            }
            setLoading(false);
        };

        fetchCredits();
    }, [currentUser]);

    return (
        <CreditsContext.Provider value={{ credits, setCredits }}>
            {!loading && children}
        </CreditsContext.Provider>
    );
};
