// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-blue-section {
    background: linear-gradient(to right, rgb(64, 133, 156), rgb(67, 0, 139));
    color: white;
    text-align: center;
    padding: 50px 20px;
}

.centered-blue-section button {
    padding: 20px 40px;
    font-size: 1.5rem;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.subtitle {
    font-size: 1.5rem;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/CenteredBlueSection.css"],"names":[],"mappings":"AAAA;IACI,yEAAyE;IACzE,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".centered-blue-section {\n    background: linear-gradient(to right, rgb(64, 133, 156), rgb(67, 0, 139));\n    color: white;\n    text-align: center;\n    padding: 50px 20px;\n}\n\n.centered-blue-section button {\n    padding: 20px 40px;\n    font-size: 1.5rem;\n    background-color: #ff5722;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.subtitle {\n    font-size: 1.5rem;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
