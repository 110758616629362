// CURRENTLY NOT IN USE
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useCredits } from './CreditsContext';
import axios from 'axios';

const domain = process.env.REACT_APP_DOMAIN;

const SuccessPage = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { setCredits } = useCredits();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyPayment = async () => {
            const sessionId = searchParams.get('session_id');

            if (sessionId && currentUser) {
                try {
                    const response = await axios.post('${domain}/api/verify-payment', {
                        sessionId,
                        userId: currentUser.uid,
                    });

                    if (response.data.success) {
                        const userDoc = doc(db, 'ep', currentUser.uid);
                        const userSnap = await getDoc(userDoc);
                        if (userSnap.exists()) {
                            const userData = userSnap.data();
                            setCredits(userData.credits);
                        }
                    } else {
                        setError('Payment verification failed.');
                    }
                } catch (err) {
                    setError('An error occurred while verifying payment.');
                }
            }

            setLoading(false);
        };

        verifyPayment();
    }, [currentUser, searchParams, setCredits]);

    const handleReturnHome = () => {
        navigate('/');
    };

    return (
        <div className="success-page">
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    <h1>Payment Successful!</h1>
                    <p>Your credits have been updated.</p>
                    <button onClick={handleReturnHome}>Return to Home</button>
                </>
            )}
        </div>
    );
};

export default SuccessPage;
