import React from 'react';
import { useTranslation } from 'react-i18next';
import './VideoSection.css';

function VideoSection() {
    const { t } = useTranslation();

    return (
        <section className="video-section" id="services">
            <div className="video-container">
                <iframe
                    width="100%"
                    height="315"
                    src={t('videoSection.videoUrl')}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <div className="text-container">
                <h2>{t('videoSection.title')}</h2>
                <p>{t('videoSection.description1')}</p>
                <p>{t('videoSection.description2')}</p>
            </div>
        </section>
    );
}

export default VideoSection;
