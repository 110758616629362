import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { generateAffiliateCode } from './utils/affiliateCode';
import { FaCopy } from 'react-icons/fa';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';

function MyAccountPage() {
    const { currentUser } = useAuth();
    const [username, setUsername] = useState('');
    const [upn, setUpn] = useState('');
    const [credits, setCredits] = useState(0);
    const [loading, setLoading] = useState(true);
    const [affiliateLink, setAffiliateLink] = useState('');
    const [transactions, setTransactions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                const userDoc = doc(db, 'ep', currentUser.uid);
                const userSnap = await getDoc(userDoc);
                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    setUsername(userData.username);
                    const upnLastFour = userData.upn.slice(-4);
                    setUpn(`**********${upnLastFour}`);
                    setCredits(userData.credits);
                    const affiliateCode = generateAffiliateCode(currentUser.uid);
                    setAffiliateLink(`${window.location.origin}/signup?ref=${affiliateCode}`);
                }

                // Fetch transactions
                const transactionsRef = collection(db, 'ep', currentUser.uid, 'transactions');
                const transactionsSnap = await getDocs(transactionsRef);
                const transactionsData = transactionsSnap.docs.map(doc => doc.data());
                setTransactions(transactionsData);

                setLoading(false);
            }
        };

        fetchUserData();
    }, [currentUser]);

    // CLICK TO COPY FUNCTION
    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert(t('myaccount.copied_to_clipboard'));
        });
    };

    const formatCurrency = (amount, currency) => {
        const formattedAmount = (amount / 100).toFixed(2); // Divide by 100 and format to 2 decimal places
        if (currency === 'USD') {
            return `$${formattedAmount}`;
        } else if (currency === 'EUR') {
            return `${formattedAmount}€`;
        } else {
            return `${currency} ${formattedAmount}`;
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5">{t('myaccount.title')}</h1>
                {loading ? (
                    <div className='min-h-[80vh] w-2/6 mx-auto flex items-center justify-center'>
                        <div className="border-4 border-dashed border-gray-300 rounded-full h-12 w-12 animate-spin"></div>
                    </div>
                ) : (
                    <>
                        <div className="bg-white p-5 rounded-lg shadow text-left max-w-xl mx-auto mb-8">
                            <h3 className="text-2xl mb-3">{t('myaccount.account_details')}</h3>
                            <div className="flex items-center justify-between mb-5">
                                <p className="text-lg">{t('myaccount.username')}: {username}</p>
                                <FaCopy
                                    onClick={() => handleCopyClick(username)}
                                    className="cursor-pointer text-2xl text-blue-600 hover:text-blue-800"
                                    title={t('myaccount.copy_to_clipboard')}
                                />
                            </div>
                            <div className="flex items-center justify-between mb-5">
                                <p className="text-lg">{t('myaccount.upn')}: {upn}</p>
                            </div>
                            <p className="text-lg">{t('myaccount.credits')}: {credits}</p>
                            <h3 className="text-2xl mt-12 mb-3">{t('myaccount.invite_friends')}</h3>
                            <p className="text-lg">{t('myaccount.share_affiliate_link')}</p>
                            <div className="flex items-center gap-2 mt-2">
                                <input
                                    type="text"
                                    value={affiliateLink}
                                    readOnly
                                    onClick={(e) => e.target.select()}
                                    className="flex-1 p-2 border border-gray-300 rounded"
                                />
                                <FaCopy
                                    onClick={() => handleCopyClick(affiliateLink)}
                                    className="cursor-pointer text-2xl text-blue-600 hover:text-blue-800"
                                    title={t('myaccount.copy_to_clipboard')}
                                />
                            </div>
                        </div>

                        {/* Payment History Section */}
                        <div className="bg-white p-5 rounded-lg shadow text-left max-w-xl mx-auto mb-8">
                            <h3 className="text-2xl mb-3">{t('myaccount.payment_history')}</h3>
                            <table className="w-full border-separate border-spacing-y-2">
                                <thead>
                                    <tr>
                                        <th className="p-2 bg-gray-200">{t('myaccount.transaction_date')}</th>
                                        <th className="p-2 bg-gray-200">{t('myaccount.credits_purchased')}</th>
                                        <th className="p-2 bg-gray-200">{t('myaccount.transaction_amount')}</th>
                                        <th className="p-2 bg-gray-200">{t('myaccount.invoice')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.map((transaction, index) => (
                                        <tr key={index} className="bg-white border-b border-gray-300">
                                            <td className="p-2">{new Date(transaction.date.seconds * 1000).toLocaleDateString('en-GB')}</td>
                                            <td className="p-2">{transaction.credits}</td>
                                            <td className="p-2">{formatCurrency(transaction.amount, transaction.currency)}</td>
                                            <td className="p-2">
                                                {transaction.invoice_pdf ? (
                                                    <a
                                                        href={transaction.invoice_pdf}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-600 hover:text-blue-800"
                                                    >
                                                        {t('myaccount.download_invoice')}
                                                    </a>
                                                ) : (
                                                    t('myaccount.no_invoice')
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default MyAccountPage;
