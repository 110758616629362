// GOOGLE ANALYTICS
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-0W34K41EKG');
};

export const logPageView = (url) => {
    window.gtag('config', 'G-0W34K41EKG', {
        page_path: url,
    });
};
