import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bgvid from './bgvid.mp4';

function Hero() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className="relative h-screen md:h-[80vh] flex items-center md:pl-12 overflow-hidden">
            <video autoPlay loop muted className="absolute top-0 left-0 w-full h-full object-cover z-[-1]" onError={(e) => console.error('Error loading video:', e)}>
                <source src={bgvid} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="relative text-white text-left w-[80%] max-w-xl z-[1] mx-auto md:mx-2 pt-20">
                <h1 className="text-5xl mb-5">{t('hero.title')}</h1>
                <p className="subtitle text-2xl mb-5">{t('hero.subtitle')}</p>
                <button onClick={() => navigate('/signup')} className="text-xl px-6 py-3 bg-[#ff5722] text-white rounded">
                    {t('hero.button')}
                </button>
            </div>
        </section>
    );
}

export default Hero;
