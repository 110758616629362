import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import SignUpPage from './SignUpPage';
import DashboardPage from './DashboardPage';
import MyAccountPage from './MyAccountPage';
import TestAlsaMindPage from './TestAlsaMindPage';
import MyPersonalityPage from './MyPersonalityPage';
import MyCreditsPage from './MyCreditsPage';
import PurchasePage from './PurchasePage';
import { AuthProvider, useAuth } from './AuthContext';
import { CreditsProvider } from './CreditsContext';
import { RelationshipProvider } from './RelationshipContext';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './stripe';
import Footer from './components/Footer';
import MyRelationshipsPage from './MyRelationshipsPage';
import ConnectedPeoplePage from './ConnectedPeoplePage';
import AddedPeoplePage from './AddedPeoplePage';
import PersonPage from './PersonPage';
import AddManualPersonPage from './AddManualPersonPage';
import ManualListPage from './ManualListPage';
import AgeRestrictionPage from './AgeRestrictionPage';
import AddSomeonePage from './AddSomeonePage';
import ManualPersonPage from './ManualPersonPage';
import { ApproveProvider } from './ApproveContext';
import ApproveConnectionPage from './ApproveConnectionPage';
import { LanguageProvider } from './LanguageContext';
import './i18n';
import './css/CookieConsent.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector';
import Legal from './Legal';
// import CheckoutPage from './CheckoutPage';
import { CheckoutProvider } from './CheckoutContext';
import SuccessPage from './SuccessPage';
import LocationRestrictionPage from './LocationRestrictionPage';
import ForgotPassword from './ForgotPassword';
import WorkWithCoFounderPage from './WorkWithCoFounderPage';
import { initGA, logPageView } from './analytics';
import UpnTakenPage from './UpnTakenPage';
import ChatbotScript from './ChatbotScript'; // Import the new component

function PrivateRoute({ element }) {
    const { currentUser } = useAuth();
    return currentUser ? element : <Navigate to="/login" />;
}

function App() {
    const location = useLocation();
    const { t } = useTranslation();
    const [showCookieConsent, setShowCookieConsent] = useState(false);

    useEffect(() => {
        if (Cookies.get('accepted-cookies') === 'true') {
            initGA();
        }
    }, []);

    useEffect(() => {
        if (Cookies.get('accepted-cookies') === 'true') {
            logPageView(location.pathname);
        }
    }, [location]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowCookieConsent(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <AuthProvider>
            <CreditsProvider>
                <RelationshipProvider>
                    <ApproveProvider>
                        <LanguageProvider>
                            <CheckoutProvider>
                                <Elements stripe={stripePromise}>
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/login" element={<LoginPage />} />
                                        <Route path="/signup" element={<SignUpPage />} />
                                        <Route path="/dashboard" element={<PrivateRoute element={<DashboardPage />} />} />
                                        <Route path="/myaccount" element={<PrivateRoute element={<MyAccountPage />} />} />
                                        <Route path="/mypersonality" element={<PrivateRoute element={<MyPersonalityPage />} />} />
                                        <Route path="/mycredits" element={<PrivateRoute element={<MyCreditsPage />} />} />
                                        <Route path="/test-alsamind" element={<PrivateRoute element={<TestAlsaMindPage />} />} />
                                        <Route path="/purchase/:packageId" element={<PrivateRoute element={<PurchasePage />} />} />
                                        <Route path="/myrelationships" element={<PrivateRoute element={<MyRelationshipsPage />} />} />
                                        <Route path="/connectedpeople" element={<PrivateRoute element={<ConnectedPeoplePage />} />} />
                                        <Route path="/addedpeople" element={<PrivateRoute element={<AddedPeoplePage />} />} />
                                        <Route path="/person" element={<PrivateRoute element={<PersonPage />} />} />
                                        <Route path="/addmanualperson" element={<PrivateRoute element={<AddManualPersonPage />} />} />
                                        <Route path="/manualpeople" element={<PrivateRoute element={<ManualListPage />} />} />
                                        <Route path="/age-restriction" element={<AgeRestrictionPage />} />
                                        <Route path="/add-someone" element={<PrivateRoute element={<AddSomeonePage />} />} />
                                        <Route path="/manualperson" element={<PrivateRoute element={<ManualPersonPage />} />} />
                                        <Route path="/approve-connection" element={<PrivateRoute element={<ApproveConnectionPage />} />} />
                                        <Route path="/legal" element={<Legal />} />
                                        <Route path="/work-with-cofounder" element={<WorkWithCoFounderPage />} />
                                        {/* <Route path="/checkout" element={<PrivateRoute element={<CheckoutPage />} />} /> */}
                                        <Route path="/success" element={<PrivateRoute element={<SuccessPage />} />} />
                                        <Route path="/location-restriction" element={<LocationRestrictionPage />} />
                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                        <Route path="/upn-taken" element={<UpnTakenPage />} />
                                    </Routes>

                                </Elements>
                                <LanguageSelector />
                                <Footer />
                                {showCookieConsent && (
                                    <CookieConsent
                                        location="bottom"
                                        buttonText={t('cookieConsent.accept')}
                                        declineButtonText={t('cookieConsent.reject')}
                                        cookieName="accepted-cookies"
                                        enableDeclineButton
                                        onAccept={() => {
                                            initGA();
                                            logPageView(location.pathname);
                                        }}
                                        onDecline={() => {
                                            console.log('Cookies rejected');
                                        }}
                                        containerClasses="cookie-consent-banner visible"
                                        buttonWrapperClasses="cookie-consent-buttons"
                                        buttonClasses="cookie-consent-button"
                                        declineButtonClasses="cookie-consent-button reject"
                                    >
                                        <div className="cookie-consent-content relative">
                                            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                                <div className='text-left'>
                                                    {t('cookieConsent.message')}
                                                    <a href="/legal" className="cookie-link">{t('cookieConsent.learnMore')}</a>
                                                </div>
                                            </div>
                                        </div>

                                    </CookieConsent>
                                )}
                                <ChatbotScript />
                            </CheckoutProvider>
                        </LanguageProvider>
                    </ApproveProvider>
                </RelationshipProvider>
            </CreditsProvider>
        </AuthProvider>
    );
}

const MainApp = () => (
    <Router>
        <App />
    </Router>
);

export default MainApp;
