// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal-section {
    padding: 50px 20px;
    margin-top: 70px;

}

.legal-section p {
    font-size: 0.8rem;
    color: #666;
    margin-top: 10px;
}

.legal-content h2 {
    margin-top: 20px;
    font-size: 1.5rem;
}

.legal-content p {
    font-size: 0.8rem;
    color: #666;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/Legal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".legal-section {\n    padding: 50px 20px;\n    margin-top: 70px;\n\n}\n\n.legal-section p {\n    font-size: 0.8rem;\n    color: #666;\n    margin-top: 10px;\n}\n\n.legal-content h2 {\n    margin-top: 20px;\n    font-size: 1.5rem;\n}\n\n.legal-content p {\n    font-size: 0.8rem;\n    color: #666;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
