// THIS COMPONENT HANDLES TRANSLATIONS FOR THE APP
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fr/translation.json';
import esTranslation from './locales/es/translation.json';
import deTranslation from './locales/de/translation.json';
import itTranslation from './locales/it/translation.json';
import cnTranslation from './locales/cn/translation.json';
import ptTranslation from './locales/pt/translation.json';
import jpTranslation from './locales/jp/translation.json';

// The translations
const resources = {
    en: {
        translation: enTranslation,
    },
    fr: {
        translation: frTranslation,
    },
    es: {
        translation: esTranslation,
    },
    de: {
        translation: deTranslation,
    },
    it: {
        translation: itTranslation,
    },
    cn: {
        translation: cnTranslation,
    },
    pt: {
        translation: ptTranslation,
    },
    jp: {
        translation: jpTranslation,
    },
};

i18n
    .use(initReactI18next) // pass the i18n instance to react-i18next
    .init({
        resources,
        lng: 'en', // default language
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
