// THIS COMPONENT IS FOR MANAGING THE AUTHENTICATION STATE OF THE APP
import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from './firebaseConfig';
import {
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    async function signup(email, password) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        try {
            await sendEmailVerification(userCredential.user);
        } catch (error) {
            console.error('Error sending email verification:', error);
        }
        return userCredential;
    }

    async function login(email, password) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
            throw new Error('Please verify your email before logging in.');
        }
        return userCredential;
    }

    async function googleSignIn() {
        const provider = new GoogleAuthProvider();
        const userCredential = await signInWithPopup(auth, provider);
        const user = userCredential.user;

        // Check if the user document exists in Firestore
        const userDocRef = doc(db, 'ep', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
            // Set initial data for new user
            await setDoc(userDocRef, {
                credits: 5,
            });
            // Indicate first login with a custom property if necessary
        }

        return userCredential;
    }

    function logout() {
        return signOut(auth);
    }

    async function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    async function resendVerificationEmail(email) {
        const user = auth.currentUser;
        if (user && user.email === email) {
            await sendEmailVerification(user);
        } else {
            const userCredential = await signInWithEmailAndPassword(auth, email, '');
            await sendEmailVerification(userCredential.user);
        }
    }

    const value = {
        currentUser,
        signup,
        login,
        googleSignIn,
        logout,
        resetPassword,
        resendVerificationEmail,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
