// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.content p {
    width: 80%;
    font-size: 24px;
    font-weight: 300;
    color: #333;
    text-align: center;
}

.okbutton {
    margin-top: 20px;
    width: 100px;
    height: 40px;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/AgeRestrictionPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".content {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 80vh;\n}\n\n.content p {\n    width: 80%;\n    font-size: 24px;\n    font-weight: 300;\n    color: #333;\n    text-align: center;\n}\n\n.okbutton {\n    margin-top: 20px;\n    width: 100px;\n    height: 40px;\n    background-color: #ff5722;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
