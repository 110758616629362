// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, deleteUser } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyA1YqCdwnX0PQqil91ELXurPL5QKhl2C5w",
    authDomain: "alsamind.firebaseapp.com",
    projectId: "alsamind",
    storageBucket: "alsamind.appspot.com",
    messagingSenderId: "336526239003",
    appId: "1:336526239003:web:d01d17b7495a41b5861fbe"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the authentication service
const auth = getAuth(app);

// Get a reference to the Firestore service
const db = getFirestore(app);

export { app, auth, db, deleteUser };
