import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LanguageContext from '../LanguageContext';

const newsletterListUids = {
    fr: '667bbdb175235',
    en: '667bbdcdf0ae3',
    it: '667bbe0353613',
    es: '667bbe1a461d9',
    jp: '667bbe83eccb6',
    de: '667bbe6f2c5fe',
    cn: '667bbe383fcd3',
    pt: '667bbdcdf0ae3'
};

function Footer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSignup = async () => {
        try {
            const listUid = newsletterListUids[language];
            const response = await axios.post(`${process.env.REACT_APP_DOMAIN}/api/newsletter-signup`, { email, listUid });
            setMessage(response.data.message);
        } catch (error) {
            setMessage("You're already signed up!");
        }
    };

    return (
        <footer className="flex justify-center bg-gray-900 py-32 min-h-80 items-center">
            <div className="w-4/5 flex flex-wrap justify-between gap-5">
                <div className="flex-1 min-w-[250px] bg-[#112338] rounded-3xl shadow-lg  items-center p-10">
                    <div className='flex md:hidden justify-center pb-4'>
                        <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" className=" md:w-full w-3/12 md:h-full text-[#ff5722]">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M13.64,9.74l-.29,1.73A1.55,1.55,0,0,0,14,13a1.46,1.46,0,0,0,1.58.09L17,12.28l1.44.79A1.46,1.46,0,0,0,20,13a1.55,1.55,0,0,0,.63-1.51l-.29-1.73,1.2-1.22a1.54,1.54,0,0,0-.85-2.6l-1.62-.24-.73-1.55a1.5,1.5,0,0,0-2.72,0l-.73,1.55-1.62.24a1.54,1.54,0,0,0-.85,2.6Zm1.83-2.13a1.51,1.51,0,0,0,1.14-.85L17,5.93l.39.83a1.55,1.55,0,0,0,1.14.86l1,.14-.73.74a1.57,1.57,0,0,0-.42,1.34l.16,1-.79-.43a1.48,1.48,0,0,0-1.44,0l-.79.43.16-1a1.54,1.54,0,0,0-.42-1.33l-.73-.75ZM21,15.26a1,1,0,0,0-1,1v3a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V9.67l5.88,5.88a2.94,2.94,0,0,0,2.1.88l.27,0a1,1,0,0,0,.91-1.08,1,1,0,0,0-1.09-.91.94.94,0,0,1-.77-.28L5.41,8.26H9a1,1,0,0,0,0-2H5a3,3,0,0,0-3,3v10a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3v-3A1,1,0,0,0,21,15.26Z"></path>
                            </g>
                        </svg>
                    </div>
                    <h3 className="text-white text-3xl mb-4 text-center md:text-left">{t('footer.join_our_newsletter')}</h3>
                    <div className='grid grid-cols-1 md:grid-cols-5 items-center gap-6'>
                        <div className="col-span-1 hidden md:flex items-center justify-center">
                            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" className=" md:w-full w-3/12 md:h-full text-[#ff5722]">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M13.64,9.74l-.29,1.73A1.55,1.55,0,0,0,14,13a1.46,1.46,0,0,0,1.58.09L17,12.28l1.44.79A1.46,1.46,0,0,0,20,13a1.55,1.55,0,0,0,.63-1.51l-.29-1.73,1.2-1.22a1.54,1.54,0,0,0-.85-2.6l-1.62-.24-.73-1.55a1.5,1.5,0,0,0-2.72,0l-.73,1.55-1.62.24a1.54,1.54,0,0,0-.85,2.6Zm1.83-2.13a1.51,1.51,0,0,0,1.14-.85L17,5.93l.39.83a1.55,1.55,0,0,0,1.14.86l1,.14-.73.74a1.57,1.57,0,0,0-.42,1.34l.16,1-.79-.43a1.48,1.48,0,0,0-1.44,0l-.79.43.16-1a1.54,1.54,0,0,0-.42-1.33l-.73-.75ZM21,15.26a1,1,0,0,0-1,1v3a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V9.67l5.88,5.88a2.94,2.94,0,0,0,2.1.88l.27,0a1,1,0,0,0,.91-1.08,1,1,0,0,0-1.09-.91.94.94,0,0,1-.77-.28L5.41,8.26H9a1,1,0,0,0,0-2H5a3,3,0,0,0-3,3v10a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3v-3A1,1,0,0,0,21,15.26Z"></path>
                                </g>
                            </svg>
                        </div>
                        <div className="col-span-1 md:col-span-4 md:flex items-center justify-start gap-4">
                            <input
                                type="email"
                                placeholder={t('footer.your_email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="p-2 w-full border border-gray-300 rounded-md text-lg"
                            />
                            <div className="flex justify-end mt-4 md:mt-0 md:justify-center md:items-center">
                                <button onClick={handleSignup} className="p-2 w-auto bg-[#ff5722] text-white rounded-md text-lg">
                                    {t('footer.subscribe')}
                                </button>
                            </div>
                        </div>
                    </div>
                    {message && <p className="text-[#ff5722] mt-3 text-center">{message}</p>}
                </div>
                <div className="flex-1 min-w-[250px] text-left">
                    <h3 className="text-white text-2xl mb-4">{t('footer.alsamind')}</h3>
                    <p className="text-gray-400 text-lg mb-2">{t('footer.address_line1')}</p>
                    <p className="text-gray-400 text-lg mb-2">{t('footer.address_line2')}</p>
                    <p className="text-gray-400 text-lg mb-2">
                        <button className="text-[#ff5722] hover:underline" onClick={() => navigate('/legal')}>{t('footer.legal')}</button>
                    </p>
                    <p className="text-gray-400 text-lg">&copy; 2024 Alsatech SAS. {t('footer.all_rights_reserved')}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
