import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CenteredBlueSection.css';
import { useTranslation } from 'react-i18next';

function CenteredBlueSection() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/signup');
    };

    return (
        <section className="centered-blue-section">
            <h2>{t('centered_blue_section.title')}</h2>
            <p className="subtitle">{t('centered_blue_section.subtitle')}</p>
            <button onClick={handleButtonClick}>{t('centered_blue_section.button')}</button>
        </section>
    );
}

export default CenteredBlueSection;
