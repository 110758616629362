import React, { useEffect, useState, useContext } from 'react';
import { useAuth } from './AuthContext';
import { useCredits } from './CreditsContext';
import { useRelationship } from './RelationshipContext';
import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc, setDoc, collection, getDocs, addDoc, query, orderBy, Timestamp } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from './LanguageContext';

const domain = process.env.REACT_APP_DOMAIN;

function ManualPersonPage() {
    const { currentUser } = useAuth();
    const { credits, setCredits } = useCredits();
    const { relationshipData, currentPersonId } = useRelationship();
    const { language } = useContext(LanguageContext);
    const [personName, setPersonName] = useState('');
    const [generalDescription, setGeneralDescription] = useState('');
    const [strengths, setStrengths] = useState('');
    const [weaknesses, setWeaknesses] = useState('');
    const [risks, setRisks] = useState('');
    const [howToMakeItWork, setHowToMakeItWork] = useState('');
    const [loading, setLoading] = useState(true);
    const [expandedRelationshipFeature, setExpandedRelationshipFeature] = useState(null);
    const [expandedPersonalityFeature, setExpandedPersonalityFeature] = useState(null);
    const [loadingRelationshipFeatures, setLoadingRelationshipFeatures] = useState({});
    const [loadingPersonalityFeatures, setLoadingPersonalityFeatures] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [personality, setPersonality] = useState({});
    const [personalityAllowed, setPersonalityAllowed] = useState(false);
    const [fixes, setFixes] = useState([]);
    const [newFixSituation, setNewFixSituation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const statusMap = {
        1: t('manual_person.status.love'),
        2: t('manual_person.status.friend'),
        3: t('manual_person.status.parent'),
        4: t('manual_person.status.child'),
        5: t('manual_person.status.family'),
        6: t('manual_person.status.boss'),
        7: t('manual_person.status.employee'),
        8: t('manual_person.status.colleague'),
    };

    const relationshipEndpoints = {
        general_description: 'relationship_description',
        strengths: 'relationship_strength',
        weaknesses: 'relationship_weakness',
        risks: 'relationship_risk',
        how_to_make_it_work: 'relationship_how',
    };

    const personalityEndpoints = {
        description: 'personality',
        strengths: 'personality_strength',
        weaknesses: 'personality_weakness',
        darkside: 'personality_darkside',
        professional_life: 'personality_professional',
        social_life: 'personality_social',
        love_life: 'personality_love',
        health: 'personality_health',
        wealth: 'personality_wealth',
        ideal_jobs: 'personality_ideal_jobs',
    };

    useEffect(() => {
        const fetchPersonData = async () => {
            if (currentUser && currentPersonId) {
                const relData = relationshipData[currentPersonId];

                if (!relData) {
                    setLoading(false);
                    return;
                }

                const targetId = relData.target;
                setPersonName(relData.name);
                setPersonalityAllowed(relData.personalityallowed || false);

                try {
                    if (relData.personalityallowed) {
                        const personalityDoc = doc(db, 'personality', targetId);
                        const personalitySnap = await getDoc(personalityDoc);

                        if (personalitySnap.exists()) {
                            setPersonality(personalitySnap.data());
                        } else {
                            await setDoc(personalityDoc, {});
                        }
                    }

                    const relationshipDoc = doc(db, 'rel', currentPersonId);
                    const relationshipSnap = await getDoc(relationshipDoc);

                    if (relationshipSnap.exists()) {
                        const data = relationshipSnap.data();
                        setGeneralDescription(data.general_description || '');
                        setStrengths(data.strengths || '');
                        setWeaknesses(data.weaknesses || '');
                        setRisks(data.risks || '');
                        setHowToMakeItWork(data.how_to_make_it_work || '');
                    }

                    // Fetch fixes
                    const fixesQuery = query(collection(db, 'rel', currentPersonId, 'fixes'), orderBy('date_asked', 'desc'));
                    const fixesSnapshot = await getDocs(fixesQuery);

                    const fixesList = fixesSnapshot.docs.map(doc => {
                        const fixData = doc.data();
                        return {
                            id: doc.id,
                            date: fixData.date_asked instanceof Timestamp ? fixData.date_asked.toDate() : null,
                            situation: fixData.situation,
                            result: fixData.result
                        };
                    });

                    setFixes(fixesList);

                } catch (error) {
                    console.error('Error fetching data:', error);
                    alert('Failed to retrieve data: ' + error.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchPersonData();
    }, [currentUser, currentPersonId, relationshipData]);

    const calculateFeature = async (endpoint, stateSetter, featureName, creditsRequired) => {
        if (Object.values(loadingRelationshipFeatures).some((isLoading) => isLoading) ||
            Object.values(loadingPersonalityFeatures).some((isLoading) => isLoading)) {
            setShowPopup(true);
            return;
        }

        if (credits < creditsRequired) {
            navigate('/mycredits');
            return;
        }

        setLoadingRelationshipFeatures((prev) => ({ ...prev, [featureName]: true }));

        try {
            const userDoc = await getDoc(doc(db, 'ep', currentUser.uid));
            const targetUserDoc = await getDoc(doc(db, 'ep', relationshipData[currentPersonId].target));

            if (!userDoc.exists() || !targetUserDoc.exists()) {
                throw new Error('User data not found');
            }

            const userUPN = userDoc.data().upn;
            const targetUPN = targetUserDoc.data().upn;
            const status = statusMap[relationshipData[currentPersonId].status];
            const name = relationshipData[currentPersonId].name;

            const relationshipParams = new URLSearchParams({
                upn: userUPN,
                upn2: targetUPN,
                status,
                name,
                language: language,
            }).toString();

            const apiUrl = `${domain}/api/${endpoint}?${relationshipParams}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const content = data.result;

            const relationshipDocRef = doc(db, 'rel', currentPersonId);
            await updateDoc(relationshipDocRef, {
                [featureName]: content,
            });

            const userDocRef = doc(db, 'ep', currentUser.uid);
            await updateDoc(userDocRef, {
                credits: credits - creditsRequired,
            });

            stateSetter(content);
            setCredits((prevCredits) => prevCredits - creditsRequired);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to retrieve data: ' + error.message);
        } finally {
            setLoadingRelationshipFeatures((prev) => ({ ...prev, [featureName]: false }));
        }
    };

    const calculatePersonalityFeature = async (featureName) => {
        if (Object.values(loadingRelationshipFeatures).some((isLoading) => isLoading) ||
            Object.values(loadingPersonalityFeatures).some((isLoading) => isLoading)) {
            setShowPopup(true);
            return;
        }

        if (credits < 1) {
            navigate('/mycredits');
            return;
        }

        setLoadingPersonalityFeatures((prev) => ({ ...prev, [featureName]: true }));

        try {
            const userDoc = await getDoc(doc(db, 'ep', currentUser.uid));
            const targetUserDoc = await getDoc(doc(db, 'ep', relationshipData[currentPersonId].target));

            if (!userDoc.exists() || !targetUserDoc.exists()) {
                throw new Error('User data not found');
            }

            const targetUPN = targetUserDoc.data().upn;

            const personalityParams = new URLSearchParams({
                upn: targetUPN,
                language: language,
            }).toString();

            const apiUrl = `${domain}/api/${personalityEndpoints[featureName]}?${personalityParams}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const content = data.result;

            const personalityDocRef = doc(db, 'personality', relationshipData[currentPersonId].target);
            await updateDoc(personalityDocRef, {
                [featureName]: content,
            });

            const userDocRef = doc(db, 'ep', currentUser.uid);
            await updateDoc(userDocRef, {
                credits: credits - 1,
            });

            setPersonality((prevPersonality) => ({
                ...prevPersonality,
                [featureName]: content,
            }));
            setCredits((prevCredits) => prevCredits - 1);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to retrieve data: ' + error.message);
        } finally {
            setLoadingPersonalityFeatures((prev) => ({ ...prev, [featureName]: false }));
        }
    };

    const handleExpandRelationshipFeature = (featureName) => {
        setExpandedRelationshipFeature((prev) => (prev === featureName ? null : featureName));
    };

    const handleExpandPersonalityFeature = (featureName) => {
        setExpandedPersonalityFeature((prev) => (prev === featureName ? null : featureName));
    };

    const renderContent = (content) => {
        const filteredContent = content.replace(/[*#]/g, '');
        const parts = filteredContent.split('\n');
        const elements = parts.map((part, index) => {
            if (/^(\d+\.\s|[-*]\s)/.test(part.trim())) {
                return <li key={index}>{part.trim()}</li>;
            }
            return <p key={index}>{part.trim()}</p>;
        });

        return elements.some(element => element.type === 'li') ? (
            <ul>{elements}</ul>
        ) : (
            elements
        );
    };

    const renderFeatureCard = (featureName, content) => (
        <div key={featureName} className={`relative ${expandedRelationshipFeature === featureName ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
            {expandedRelationshipFeature === featureName ? (
                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpandRelationshipFeature(featureName)}>
                    &times;
                </button>
            ) : ""}
            <div className="person-feature-card p-6 bg-white rounded-lg shadow-md h-full">
                <h3 className="font-medium text-2xl mb-4 capitalize">{t(`person.${featureName}`)}</h3>
                {expandedRelationshipFeature === featureName ? (
                    <>
                        <div className="person-feature-content relative">
                            {renderContent(content)}
                        </div>
                        <div className='flex justify-center items-center'>
                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpandRelationshipFeature(featureName)}>{t('close')}</button>
                        </div>
                    </>
                ) : (
                    <button
                        onClick={() => content ? handleExpandRelationshipFeature(featureName) : calculateFeature(
                            relationshipEndpoints[featureName],
                            stateSetterMap[featureName],
                            featureName,
                            2
                        )}
                        disabled={loadingRelationshipFeatures[featureName]}
                        className={`mt-5 px-5 py-2 text-white rounded ${content ? 'bg-green-500' : 'bg-blue-500'}`}
                    >
                        {loadingRelationshipFeatures[featureName] ? (
                            <>
                                <div className="border-2 border-t-2 border-t-blue-500 border-gray-300 rounded-full w-6 h-6 animate-spin inline-block mr-2"></div>
                                {t('manual_person.calculating')}
                            </>
                        ) : content ? (
                            t('manual_person.read')
                        ) : credits < 2 ? (
                            t('manual_person.add_credits')
                        ) : (
                            `${t('manual_person.calculate')} (2c)`
                        )}
                    </button>
                )}
            </div>
        </div>

    );

    const renderPersonalityFeature = (featureName, content) => (
        <div key={featureName} className={`relative ${expandedPersonalityFeature === featureName ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'} flex flex-col`}>
            {expandedPersonalityFeature === featureName ? (
                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpandPersonalityFeature(featureName)}>
                    &times;
                </button>
            ) : ""}
            <div className="person-feature-card p-6 bg-white rounded-lg shadow-md flex-1">
                <h3 className="font-medium text-2xl mb-4 capitalize">{t(`personality.${featureName}`)}</h3>
                {expandedPersonalityFeature === featureName ? (
                    <>
                        <div className="person-feature-content relative">
                            {renderContent(content)}
                        </div>
                        <div className='flex justify-center items-center'>
                            <button className=" w-auto person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpandPersonalityFeature(featureName)}>{t('close')}</button>
                        </div>
                    </>
                ) : (
                    <button
                        onClick={() => content ? handleExpandPersonalityFeature(featureName) : calculatePersonalityFeature(featureName)}
                        disabled={loadingPersonalityFeatures[featureName]}
                        className={`mt-5 px-5 py-2 text-white rounded ${content ? 'bg-green-500' : 'bg-blue-500'}`}
                    >
                        {loadingPersonalityFeatures[featureName] ? (
                            <>
                                <div className="border-2 border-t-2 border-t-blue-500 border-gray-300 rounded-full w-6 h-6 animate-spin inline-block mr-2"></div>
                                {t('manual_person.calculating')}
                            </>
                        ) : content ? (
                            t('manual_person.read')
                        ) : credits < 1 ? (
                            t('manual_person.add_credits')
                        ) : (
                            `${t('manual_person.calculate')} (1c)`
                        )}
                    </button>
                )}
            </div>
        </div>
    );

    const handleAddFix = async (e) => {
        e.preventDefault();
        if (!newFixSituation) return;

        setErrorMessage('');  // Clear any previous error message

        if (credits < 2) {
            navigate('/mycredits');
            return;
        }

        setLoadingRelationshipFeatures((prev) => ({ ...prev, add_fix: true }));

        try {
            const userDoc = await getDoc(doc(db, 'ep', currentUser.uid));
            const targetUserDoc = await getDoc(doc(db, 'ep', relationshipData[currentPersonId].target));

            if (!userDoc.exists() || !targetUserDoc.exists()) {
                throw new Error('User data not found');
            }

            const userUPN = userDoc.data().upn;
            const targetUPN = targetUserDoc.data().upn;
            const status = statusMap[relationshipData[currentPersonId].status];
            const name = relationshipData[currentPersonId].name;

            // Check if the situation can be handled
            const checkParams = new URLSearchParams({
                situation: newFixSituation,
                // language: language,
            }).toString();

            const checkApiUrl = `${domain}/api/check_if_situation?${checkParams}`;
            const checkResponse = await fetch(checkApiUrl);
            console.log('checkResponse:', checkResponse);

            if (!checkResponse.ok) {
                throw new Error('Failed to check the situation');
            }

            const checkData = await checkResponse.json();
            console.log('checkData:', checkData);

            if (!checkData.result) {
                console.log('Situation not handled:', checkData);
                setErrorMessage(t('person.situation_not_handled'));
                setLoadingRelationshipFeatures((prev) => ({ ...prev, add_fix: false }));
                return;
            }

            const relationshipParams = new URLSearchParams({
                upn: userUPN,
                upn2: targetUPN,
                status,
                name,
                language: language,
                situation: newFixSituation,
            }).toString();

            const apiUrl = `${domain}/api/relationship_fix?${relationshipParams}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const result = data.result;

            const newFix = {
                date_asked: Timestamp.now(),
                situation: newFixSituation,
                result,
            };

            await addDoc(collection(db, 'rel', currentPersonId, 'fixes'), newFix);

            setFixes(prevFixes => [{ ...newFix, id: Date.now().toString(), date: newFix.date_asked.toDate() }, ...prevFixes]);
            const userDocRef = doc(db, 'ep', currentUser.uid);
            await updateDoc(userDocRef, {
                credits: credits - 2,
            });

            setCredits((prevCredits) => prevCredits - 2);
            setNewFixSituation('');
        } catch (error) {
            console.error('Error adding new fix:', error);
            alert('Failed to add new fix: ' + error.message);
        } finally {
            setLoadingRelationshipFeatures((prev) => ({ ...prev, add_fix: false }));
        }
    };

    const stateSetterMap = {
        general_description: setGeneralDescription,
        strengths: setStrengths,
        weaknesses: setWeaknesses,
        risks: setRisks,
        how_to_make_it_work: setHowToMakeItWork,
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5 text-center">{t('manual_person.you_and', { personName })}</h1>
                <p className="text-xl mb-10 text-left">{t('manual_person.page_description', { personName })}</p>
                <div className="mb-10">
                    <h2 className="text-3xl mb-5 text-left">{t('manual_person.relationship_description')}</h2>
                    <p className="text-xl mb-5 text-left">{t('manual_person.relationship_description_details')}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                        {renderFeatureCard('general_description', generalDescription)}
                        {renderFeatureCard('strengths', strengths)}
                        {renderFeatureCard('weaknesses', weaknesses)}
                        {renderFeatureCard('risks', risks)}
                        {renderFeatureCard('how_to_make_it_work', howToMakeItWork)}
                    </div>
                </div>

                <div className="mb-10">
                    <h2 className="text-3xl mb-5 text-left">{t('manual_person.personality_title', { personName })}</h2>
                    <p className="text-xl mb-5 text-left">{t('manual_person.personality_description', { personName })}</p>
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5">
                        {loading ? (
                            <p>{t('manual_person.loading')}</p>
                        ) : personalityAllowed ? (
                            Object.keys(personalityEndpoints).map((key) => renderPersonalityFeature(key, personality[key] || ''))
                        ) : (
                            <p>{t('manual_person.personality_not_shared')}</p>
                        )}
                    </div>
                </div>

                <div className="mb-10">
                    <h2 className="text-3xl mb-5 text-left">{t('manual_person.fix_relationship')}</h2>
                    <p className="text-xl mb-5 text-left">{t('manual_person.fix_relationship_description')}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="person-feature-card p-6 bg-white rounded-lg shadow-md text-center col-span-1">
                            <h3 className="font-medium text-2xl mb-4">{t('person.add_fix')}</h3>
                            <form onSubmit={handleAddFix} className="person-add-fix-form">
                                <textarea
                                    value={newFixSituation}
                                    onChange={(e) => setNewFixSituation(e.target.value)}
                                    placeholder={t('person.describe_situation')}
                                    className="w-full mb-2 p-2 border border-gray-300 rounded"
                                    required
                                />
                                <button type="submit" disabled={loadingRelationshipFeatures.add_fix} className="bg-blue-500 text-white rounded px-4 py-2">
                                    {loadingRelationshipFeatures.add_fix ? (
                                        <>
                                            <div className="person-spinner border-t-2 border-blue-500 border-2 w-4 h-4 rounded-full animate-spin inline-block"></div>
                                            {t('calculating')}
                                        </>
                                    ) : (
                                        `${t('person.add_fix_button')} (2c)`
                                    )}
                                </button>
                            </form>
                            {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
                        </div>
                        {fixes.map((fix) => (
                            <div key={fix.id} className={`relative ${expandedRelationshipFeature === fix.id ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                                {expandedRelationshipFeature === fix.id && (
                                    <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpandRelationshipFeature(fix.id)}>
                                        &times;
                                    </button>
                                )}
                                <div className='person-feature-card p-6 bg-white rounded-lg shadow-md'>
                                    <p>Date: {fix.date ? fix.date.toLocaleDateString() : ''}</p>
                                    <p>{expandedRelationshipFeature === fix.id ? fix.situation : `${fix.situation.substring(0, 60)}...`}</p>
                                    {expandedRelationshipFeature !== fix.id && (
                                        <button onClick={() => handleExpandRelationshipFeature(fix.id)} className="person-read-button bg-green-500 text-white rounded px-4 py-2 mt-2">
                                            {t('read')}
                                        </button>
                                    )}
                                    {expandedRelationshipFeature === fix.id && (
                                        <div className="person-feature-content">
                                            {renderContent(fix.result)}
                                            <div className='flex justify-center items-center'>
                                                <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpandRelationshipFeature(fix.id)}>{t('close')}</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-10">
                    <h2 className="text-3xl mb-5 text-left">{t('manual_person.relationship_time_travel')}</h2>
                    <p className="text-xl mb-5 text-left">{t('manual_person.relationship_time_travel_description')}</p>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <button disabled className="px-5 py-2 bg-gray-500 text-white rounded">{t('manual_person.coming_soon')}</button>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg text-center shadow-lg">
                        <h2 className="text-3xl mb-5 text-left">{t('manual_person.calculation_running')}</h2>
                        <p className="text-xl mb-5 text-left">{t('manual_person.please_wait')}</p>
                        <button onClick={() => setShowPopup(false)} className="px-5 py-2 bg-blue-500 text-white rounded">{t('OK')}</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ManualPersonPage;
