// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.what-alsamind-does {
    background-color: white;
    text-align: center;
    padding: 50px 20px;
}

.columns {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    gap: 40px;
}

.column {
    flex: 1 1;
    margin: 20px;
    text-align: left;
    min-width: 250px;
    margin: 20px auto;
    /* Ensures columns do not get too narrow on wider screens */
}

.column h3 {
    text-align: left;
    margin-bottom: 20px;
}

h2 {
    font-size: 3rem;
    margin-bottom: 30px;
}

h3 {
    font-size: 1.5rem;
}

p {
    font-size: 1.2rem;
    color: #666;
}

/* Media query for tablets and smaller devices */
@media (max-width: 768px) {
    .columns {
        flex-direction: column;
        align-items: center;
    }

    .column {
        margin: 20px 0;
        text-align: center;
    }

    .column h3 {
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/WhatAlsaMindDoes.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,UAAU;IACV,cAAc;IACd,SAAS;AACb;;AAEA;IACI,SAAO;IACP,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,2DAA2D;AAC/D;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA,gDAAgD;AAChD;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,cAAc;QACd,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".what-alsamind-does {\n    background-color: white;\n    text-align: center;\n    padding: 50px 20px;\n}\n\n.columns {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 30px;\n    flex-wrap: wrap;\n    width: 80%;\n    margin: 0 auto;\n    gap: 40px;\n}\n\n.column {\n    flex: 1;\n    margin: 20px;\n    text-align: left;\n    min-width: 250px;\n    margin: 20px auto;\n    /* Ensures columns do not get too narrow on wider screens */\n}\n\n.column h3 {\n    text-align: left;\n    margin-bottom: 20px;\n}\n\nh2 {\n    font-size: 3rem;\n    margin-bottom: 30px;\n}\n\nh3 {\n    font-size: 1.5rem;\n}\n\np {\n    font-size: 1.2rem;\n    color: #666;\n}\n\n/* Media query for tablets and smaller devices */\n@media (max-width: 768px) {\n    .columns {\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .column {\n        margin: 20px 0;\n        text-align: center;\n    }\n\n    .column h3 {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
