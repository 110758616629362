//THIS PAGE IS FOR WHEN SOMEONE TRIES TO ACCESS A UPN THAT WAS ALREADY SAVED
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header1 from './components/Header';
import './css/AgeRestrictionPage.css';
import { useTranslation } from 'react-i18next';

function UpnTakenPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        navigate('/login');
    };

    return (
        <div>
            <Header1 />
            <div className="content">
                <h2>{t('upntaken.restricted')}</h2>
                <p>{t('upntaken.message')}</p>
                <p>{t('upntaken.deletedMessage')}</p>
                <button className='okbutton' onClick={handleClose}>{t('upntaken.okButton')}</button>
            </div>
        </div>
    );
}

export default UpnTakenPage;
