import React, { useState, useCallback } from 'react';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, setDoc, getDoc, updateDoc, collection } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const domain = process.env.REACT_APP_DOMAIN;

const libraries = ['places'];

function AddManualPersonPage() {
    const { currentUser } = useAuth();
    const [birthDate, setBirthDate] = useState('');
    const [birthTime, setBirthTime] = useState('');
    const [location, setLocation] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: 48.5276, lng: 7.2137 });
    const [isDST, setIsDST] = useState(false);
    const [manualPersonName, setManualPersonName] = useState('');
    const [gender, setGender] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [missingFields, setMissingFields] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const statusOptions = [
        { value: '', label: t('add_manual_person.status.select') },
        { value: 1, label: t('add_manual_person.status.love') },
        { value: 2, label: t('add_manual_person.status.friend') },
        { value: 3, label: t('add_manual_person.status.parent') },
        { value: 4, label: t('add_manual_person.status.child') },
        { value: 5, label: t('add_manual_person.status.family') },
        { value: 6, label: t('add_manual_person.status.boss') },
        { value: 7, label: t('add_manual_person.status.employee') },
        { value: 8, label: t('add_manual_person.status.colleague') },
    ];

    const onLoad = useCallback((autocomplete) => {
        if (!autocomplete) {
            console.error('Autocomplete is not loaded properly');
            return;
        }
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                alert("No details available for input: '" + place.name + "'");
                return;
            }
            setLocation(place.formatted_address);
            setCoordinates({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const missing = [];
        if (!manualPersonName) missing.push('manualPersonName');
        if (!status) missing.push('status');
        if (!gender) missing.push('gender');
        if (!birthDate) missing.push('birthDate');
        if (!birthTime) missing.push('birthTime');
        if (!location) missing.push('location');

        if (missing.length > 0) {
            setMissingFields(missing);
            setError(t('add_manual_person.error.fill_all_fields'));
            return;
        }

        if (coordinates.lat < 0) {
            setShowWarning(true);
            return;
        }

        setLoading(true);
        try {
            const birthDateTime = new Date(birthDate + 'T' + birthTime);
            let hour = birthDateTime.getHours();
            if (isDST) {
                hour -= 1;
            }

            const params = { y: birthDateTime.getFullYear(), m: birthDateTime.getMonth() + 1, d: birthDateTime.getDate(), h: hour };
            const queryString = new URLSearchParams(params).toString();
            const apiUrl = `${domain}/api/calculate_ep?${queryString}`;

            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const upn = data.result[data.result.length - 1];

            const newUserDocRef = doc(collection(db, 'ep'));
            await setDoc(newUserDocRef, {
                upn,
                gender,
                m_username: manualPersonName,
                latitude: coordinates.lat,
                longitude: coordinates.lng
            });

            const newRelDocRef = doc(collection(db, 'rel'));
            await setDoc(newRelDocRef, {
                ispending: false,
                origin: currentUser.uid,
                target: newUserDocRef.id,
                personalityallowed: true,
                timetravelallowed: false,
                status,
                name: manualPersonName,
            });

            const manualepDocRef = doc(db, 'manualep', currentUser.uid);
            const manualepDocSnap = await getDoc(manualepDocRef);

            if (manualepDocSnap.exists()) {
                await updateDoc(manualepDocRef, {
                    relid: [...manualepDocSnap.data().relid, newRelDocRef.id],
                });
            } else {
                await setDoc(manualepDocRef, {
                    relid: [newRelDocRef.id],
                });
            }

            navigate('/manualpeople');
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(t('add_manual_person.error.retrieve_data') + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleWarningOk = () => {
        setShowWarning(false);
        navigate('/manualpeople');
    };

    if (loadError) {
        return <div>{t('add_manual_person.error.loading_google_maps')}</div>;
    }

    if (!isLoaded) {
        return <div className='h-screen w-screen flex items-center justify-center'>
            <div className="border-4 border-dashed border-gray-300 rounded-full h-12 w-12 animate-spin"></div>
        </div>;
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36 md:pb-14">
                <h1 className="text-4xl mb-5 text-center">{t('add_manual_person.title')}</h1>
                <div className="mb-10 text-left">
                    <p className="text-md mb-4">{t('add_manual_person.description.line1')}</p>
                    <p className="text-md mb-4">{t('add_manual_person.description.line2')}</p>
                    <p className="text-md mb-4">{t('add_manual_person.description.line3')}</p>
                </div>
                <main className="grid grid-cols-1 gap-10 md:grid-cols-2 bg-white shadow-lg rounded-lg p-4">
                    <form onSubmit={handleSubmit} className="flex flex-col gap-5 text-left">
                        <div className={`flex flex-col ${missingFields.includes('manualPersonName') ? 'border-red-500' : ''}`}>
                            <label htmlFor="manualPersonName" className="mb-2 text-lg">{t('add_manual_person.person_name')}</label>
                            <input type="text" id="manualPersonName" value={manualPersonName} onChange={e => setManualPersonName(e.target.value)} placeholder={t('add_manual_person.person_name_placeholder')} className="p-3 border rounded-lg" />
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('status') ? 'border-red-500' : ''}`}>
                            <label htmlFor="status" className="mb-2 text-lg">{t('add_manual_person.status_label')}</label>
                            <select id="status" value={status} onChange={e => setStatus(e.target.value)} className="p-3 border rounded-lg">
                                {statusOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('gender') ? 'border-red-500' : ''}`}>
                            <label htmlFor="gender" className="mb-2 text-lg">{t('add_manual_person.gender')}</label>
                            <select id="gender" value={gender} onChange={e => setGender(e.target.value)} className="p-3 border rounded-lg w-full">
                                <option value="">{t('add_manual_person.select_gender')}</option>
                                <option value="male">{t('add_manual_person.male')}</option>
                                <option value="female">{t('add_manual_person.female')}</option>
                            </select>
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('birthDate') ? 'border-red-500' : ''}`}>
                            <label htmlFor="birthDate" className="mb-2 text-lg">{t('add_manual_person.birth_date')}</label>
                            <input type="date" id="birthDate" value={birthDate} onChange={e => setBirthDate(e.target.value)} className="p-3 border rounded-lg w-full" />
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('birthTime') ? 'border-red-500' : ''}`}>
                            <label htmlFor="birthTime" className="mb-2 text-lg">{t('add_manual_person.birth_time')}</label>
                            <input type="time" id="birthTime" value={birthTime} onChange={e => setBirthTime(e.target.value)} className="p-3 border rounded-lg w-full" />
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" checked={isDST} onChange={e => setIsDST(e.target.checked)} id="dstCheckbox" className="mr-3" />
                            <label htmlFor="dstCheckbox" className="text-lg">{t('add_manual_person.daylight_saving')}</label>
                        </div>
                        <div className={`flex flex-col ${missingFields.includes('location') ? 'border-red-500' : ''}`}>
                            <label htmlFor="location" className="mb-2 text-lg">{t('add_manual_person.location')}</label>
                            <Autocomplete onLoad={onLoad}>
                                <input className="p-3 border rounded-lg w-full" type="text" id="location" value={location} onChange={e => setLocation(e.target.value)} placeholder={t('add_manual_person.location_placeholder')} />
                            </Autocomplete>
                        </div>
                        {error && <p className="text-red-500 font-bold">{error}</p>}
                    </form>
                    <div className="w-full h-80 md:h-full rounded-lg overflow-hidden shadow">
                        {coordinates.lat && (
                            <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={coordinates} zoom={15}>
                                <Marker position={coordinates} />
                            </GoogleMap>
                        )}
                    </div>
                    <div className="col-span-1 md:col-span-2 flex justify-center">
                        <button onClick={handleSubmit} disabled={loading} className={`px-5 py-3 text-white rounded-lg mt-5 transition-transform transform ${loading ? 'bg-gray-500' : 'bg-blue-500 hover:scale-105'}`}>
                            {loading ? (
                                <>
                                    <div className="border-2 border-t-2 border-t-blue-500 border-gray-300 rounded-full w-6 h-6 animate-spin inline-block mr-2"></div>
                                    {t('add_manual_person.running_calculations')}
                                </>
                            ) : (
                                t('add_manual_person.add_person_button')
                            )}
                        </button>
                    </div>
                </main>
            </div>
            {showWarning && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl mb-5">{t('add_manual_person.warning_title')}</h2>
                        <p className="mb-5">{t('add_manual_person.warning_message')}</p>
                        <button onClick={handleWarningOk} className="px-5 py-2 bg-[#ff5722] text-white rounded-lg">
                            {t('add_manual_person.ok_button')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddManualPersonPage;
