import React, { useContext, useState, useRef, useEffect } from 'react';
import LanguageContext from '../LanguageContext';
// Import flag images
import enFlag from '../assets/flags/en.png';
import frFlag from '../assets/flags/fr.png';
import esFlag from '../assets/flags/es.png';
import deFlag from '../assets/flags/de.png';
import itFlag from '../assets/flags/it.png';
import cnFlag from '../assets/flags/cn.png';
import ptFlag from '../assets/flags/pt.png';
import jpFlag from '../assets/flags/jp.png';

const flags = {
    en: enFlag,
    fr: frFlag,
    de: deFlag,
    es: esFlag,
    it: itFlag,
    cn: cnFlag,
    pt: ptFlag,
    jp: jpFlag,
};

const languages = {
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    pt: 'Português',
    cn: '中文',
    jp: '日本語',
};

const LanguageSelector = () => {
    const { language, changeLanguage } = useContext(LanguageContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleLanguageChange = (lang) => {
        changeLanguage(lang);
        setDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="fixed bottom-8 right-5 z-50" ref={dropdownRef}>
            <div className="cursor-pointer" onClick={() => setDropdownOpen(!dropdownOpen)}>
                <img src={flags[language]} alt={languages[language]} className="w-9 h-6 mr-2 rounded border-2 border-gray-300 shadow-sm" />
            </div>
            {dropdownOpen && (
                <div className="absolute bottom-12 right-0 bg-white border border-gray-300 rounded shadow-lg z-50 min-w-40">
                    {Object.keys(languages).map((lang) => (
                        <div key={lang} className="flex items-center p-2 cursor-pointer hover:bg-gray-100" onClick={() => handleLanguageChange(lang)}>
                            <img src={flags[lang]} alt={languages[lang]} className="w-9 h-6 mr-2 rounded border-2 border-gray-300 shadow-sm" />
                            <div className='w-auto'>{languages[lang]}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
