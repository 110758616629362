import React, { createContext, useContext, useState } from 'react';

const ApproveContext = createContext();

export const useApprove = () => {
    return useContext(ApproveContext);
};

export const ApproveProvider = ({ children }) => {
    const [approvedPerson, setApprovedPerson] = useState(null);

    const saveApprovedPerson = (person) => {
        setApprovedPerson(person);
    };

    return (
        <ApproveContext.Provider value={{ approvedPerson, saveApprovedPerson }}>
            {children}
        </ApproveContext.Provider>
    );
};
