import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useRelationship } from './RelationshipContext';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';

function AddedPeoplePage() {
    const { currentUser } = useAuth();
    const { updateRelationshipData, setCurrentPersonId } = useRelationship();
    const [addedPeople, setAddedPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const statusMap = {
        1: t('addedpeople.status.love'),
        2: t('addedpeople.status.friend'),
        3: t('addedpeople.status.parent'),
        4: t('addedpeople.status.child'),
        5: t('addedpeople.status.family'),
        6: t('addedpeople.status.boss'),
        7: t('addedpeople.status.employee'),
        8: t('addedpeople.status.colleague'),
    };

    useEffect(() => {
        //GETS THE LIST OF ADDED PEOPLE FROM THE RELATIONSHIPS COLLECTION
        const fetchAddedPeople = async () => {
            if (currentUser) {
                const relationshipDoc = doc(db, 'relationships', currentUser.uid);
                const docSnap = await getDoc(relationshipDoc);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const relIds = data.relid || [];
                    const peopleData = await Promise.all(
                        relIds.map(async (relId) => {
                            const relDoc = await getDoc(doc(db, 'rel', relId));
                            if (relDoc.exists()) {
                                const relData = relDoc.data();
                                updateRelationshipData(relId, relData);
                                return { id: relId, ...relData };
                            }
                            return null;
                        })
                    );
                    setAddedPeople(peopleData.filter(person => person !== null));
                }
                setLoading(false);
            }
        };

        fetchAddedPeople();
    }, [currentUser, updateRelationshipData]);

    // HANDLES OPENING A PERSON BY SAVING IT TO THE CURRENT PERSON ID TO THE RELATIONSHIP CONTEXT AND NAVIGATING TO THE PERSON PAGE
    const handleOpenPerson = (personId) => {
        setCurrentPersonId(personId);
        navigate(`/person`);
    };

    // HANDLES ADDING A NEW PERSON BY NAVIGATING TO THE ADD SOMEONE PAGE
    const handleAddNewPerson = () => {
        navigate('/add-someone');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5">{t('addedpeople.title')}</h1>
                <p className="text-xl mb-10 text-left">{t('addedpeople.description')}</p>
                {loading ? (
                    <p>{t('addedpeople.loading')}</p>
                ) : (
                    <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                        <div className="flex flex-col items-center justify-center bg-gray-100 border-2 border-dashed border-gray-300 cursor-pointer transition hover:bg-gray-200 p-5 rounded-lg" onClick={handleAddNewPerson}>
                            <div className="text-4xl font-bold text-gray-500 mb-3">+</div>
                            <button className="bg-[#ff5722] text-white px-5 py-2 rounded-lg hover:bg-[#ff5722]">{t('addedpeople.add_button')}</button>
                        </div>
                        {addedPeople.map((person) => (
                            <div key={person.id} className="bg-white p-5 rounded-lg shadow text-center">
                                <h2 className="text-2xl mb-5">{person.name}</h2>
                                <p className="text-lg mb-5">{t('addedpeople.status_label')}: {statusMap[person.status]}</p>
                                <button
                                    className={`px-5 py-2 text-white rounded-lg ${person.ispending ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500'}`}
                                    onClick={() => handleOpenPerson(person.id)}
                                    disabled={person.ispending}
                                >
                                    {person.ispending ? t('addedpeople.pending') : t('addedpeople.open')}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default AddedPeoplePage;
