//CURRENTLY NOT USED
import React, { createContext, useContext, useState } from 'react';

const CheckoutContext = createContext();

export const useCheckout = () => {
    return useContext(CheckoutContext);
};

export const CheckoutProvider = ({ children }) => {
    const [checkoutItem, setCheckoutItem] = useState(null);
    const [checkoutCountry, setCheckoutCountry] = useState('');

    return (
        <CheckoutContext.Provider value={{ checkoutItem, setCheckoutItem, checkoutCountry, setCheckoutCountry }}>
            {children}
        </CheckoutContext.Provider>
    );
};
