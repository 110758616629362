import React, { useEffect, useState, useContext } from 'react';
import { useAuth } from './AuthContext';
import { useCredits } from './CreditsContext';
import { useRelationship } from './RelationshipContext';
import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc, collection, getDocs, addDoc, query, orderBy } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from './LanguageContext';

const domain = process.env.REACT_APP_DOMAIN;

function PersonPage() {
    const { currentUser } = useAuth();
    const { credits, setCredits } = useCredits();
    const { relationshipData, currentPersonId } = useRelationship();
    const { language } = useContext(LanguageContext);
    const [personName, setPersonName] = useState('');
    const [personalityAllowed, setPersonalityAllowed] = useState(false);
    const [personality, setPersonality] = useState({});
    const [generalDescription, setGeneralDescription] = useState('');
    const [strengths, setStrengths] = useState('');
    const [weaknesses, setWeaknesses] = useState('');
    const [risks, setRisks] = useState('');
    const [howToMakeItWork, setHowToMakeItWork] = useState('');
    const [loading, setLoading] = useState(true);
    const [expandedFeature, setExpandedFeature] = useState(null);
    const [loadingFeatures, setLoadingFeatures] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [fixes, setFixes] = useState([]);
    const [newFixSituation, setNewFixSituation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const statusMap = {
        1: t('status.love'),
        2: t('status.friend'),
        3: t('status.parent'),
        4: t('status.child'),
        5: t('status.family'),
        6: t('status.boss'),
        7: t('status.employee'),
        8: t('status.colleague'),
    };

    const relationshipEndpoints = {
        general_description: 'relationship_description',
        strengths: 'relationship_strength',
        weaknesses: 'relationship_weakness',
        risks: 'relationship_risk',
        how_to_make_it_work: 'relationship_how',
    };

    const personalityEndpoints = {
        description: 'personality',
        strengths: 'personality_strength',
        weaknesses: 'personality_weakness',
        darkside: 'personality_darkside',
        professional_life: 'personality_professional',
        social_life: 'personality_social',
        love_life: 'personality_love',
        health: 'personality_health',
        wealth: 'personality_wealth',
        ideal_jobs: 'personality_ideal_jobs',
    };

    useEffect(() => {
        const fetchPersonData = async () => {
            if (currentUser && currentPersonId) {
                const relData = relationshipData[currentPersonId];

                if (!relData) {
                    setLoading(false);
                    return;
                }

                const targetId = relData.target;
                setPersonName(relData.name);
                setPersonalityAllowed(relData.personalityallowed || false);

                if (relData.personalityallowed) {
                    const personalityDoc = doc(db, 'personality', targetId);
                    const personalitySnap = await getDoc(personalityDoc);

                    if (personalitySnap.exists()) {
                        setPersonality(personalitySnap.data());
                    }
                }

                const relationshipDoc = doc(db, 'rel', currentPersonId);
                const relationshipSnap = await getDoc(relationshipDoc);

                if (relationshipSnap.exists()) {
                    const data = relationshipSnap.data();
                    setGeneralDescription(data.general_description || '');
                    setStrengths(data.strengths || '');
                    setWeaknesses(data.weaknesses || '');
                    setRisks(data.risks || '');
                    setHowToMakeItWork(data.how_to_make_it_work || '');
                    setIsBlocked(data.isblocked || false);
                }

                // Fetch fixes
                const fixesQuery = query(collection(db, 'rel', currentPersonId, 'fixes'), orderBy('date_asked', 'desc'));
                const fixesSnapshot = await getDocs(fixesQuery);

                const fixesList = fixesSnapshot.docs.map(doc => {
                    const fixData = doc.data();
                    return {
                        id: doc.id,
                        date: fixData.date_asked instanceof Timestamp ? fixData.date_asked.toDate() : null,
                        situation: fixData.situation,
                        result: fixData.result
                    };
                });

                // fake data to test the UI
                // const fixesList = [
                //     {
                //         id: '1',
                //         date: new Date(),
                //         situation: 'My partner is not talking to me',
                //         result: 'Talk to your partner and ask what is wrong'
                //     },
                //     {
                //         id: '2',
                //         date: new Date(),
                //         situation: 'My partner is not talking to me',
                //         result: 'Talk to your partner and ask what is wrong'
                //     },
                // ];
                setFixes(fixesList);

                setLoading(false);
            }
        };

        fetchPersonData();
    }, [currentUser, currentPersonId, relationshipData]);

    const calculateFeature = async (endpoint, stateSetter, featureName, creditsRequired) => {
        if (Object.values(loadingFeatures).some((isLoading) => isLoading)) {
            setShowPopup(true);
            return;
        }

        if (credits < creditsRequired) {
            navigate('/mycredits');
            return;
        }

        setLoadingFeatures((prev) => ({ ...prev, [featureName]: true }));

        try {
            const userDoc = await getDoc(doc(db, 'ep', currentUser.uid));
            const targetUserDoc = await getDoc(doc(db, 'ep', relationshipData[currentPersonId].target));

            if (!userDoc.exists() || !targetUserDoc.exists()) {
                throw new Error('User data not found');
            }

            const userUPN = userDoc.data().upn;
            const targetUPN = targetUserDoc.data().upn;
            const status = statusMap[relationshipData[currentPersonId].status];
            const name = relationshipData[currentPersonId].name;

            const relationshipParams = new URLSearchParams({
                upn: userUPN,
                upn2: targetUPN,
                status,
                name,
                language: language,
            }).toString();

            const apiUrl = `${domain}/api/${endpoint}?${relationshipParams}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const content = data.result;

            const relationshipDocRef = doc(db, 'rel', currentPersonId);
            await updateDoc(relationshipDocRef, {
                [featureName]: content,
            });

            const userDocRef = doc(db, 'ep', currentUser.uid);
            await updateDoc(userDocRef, {
                credits: credits - creditsRequired,
            });

            stateSetter(content);
            setCredits((prevCredits) => prevCredits - creditsRequired);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to retrieve data: ' + error.message);
        } finally {
            setLoadingFeatures((prev) => ({ ...prev, [featureName]: false }));
        }
    };

    const handleExpand = (featureName) => {
        setExpandedFeature((prev) => (prev === featureName ? null : featureName));
    };

    const renderContent = (content) => {
        const filteredContent = content.replace(/[*#]/g, '');
        const parts = filteredContent.split('\n');
        const elements = parts.map((part, index) => {
            if (/^(\d+\.\s|[-*]\s)/.test(part.trim())) {
                return <li key={index}>{part.trim()}</li>;
            }
            return <p key={index}>{part.trim()}</p>;
        });

        return elements.some(element => element.type === 'li') ? (
            <ul>{elements}</ul>
        ) : (
            elements
        );
    };

    const renderPersonalityFeature = (featureName, content) => (
        <div key={featureName} className={`relative ${expandedFeature === featureName ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
            {expandedFeature === featureName ? (
                <button className=" absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md " onClick={() => handleExpand(featureName)}>
                    &times;
                </button>
            ) : ""}
            <div className='person-feature-card p-6 bg-white rounded-lg shadow-md '>
                <h3 className="font-medium mb-2">
                    {t(`personality.${featureName}`)}
                </h3>
                {expandedFeature === featureName ? (
                    <>
                        <div className="person-feature-content relative">

                            {renderContent(content)}
                        </div>
                        <div className='flex justify-center items-center'>
                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand(featureName)}>{t('close')}</button>
                        </div>
                    </>
                ) : (
                    <button onClick={() => handleExpand(featureName)} className="person-read-button bg-green-500 text-white rounded px-4 py-2 mt-2" disabled={isBlocked}>
                        {isBlocked ? t('blocked') : t('read')}
                    </button>
                )}
            </div>
        </div>
    );

    const renderFeatureButton = (featureName, content, endpoint, creditsRequired) => (
        <button
            onClick={() => content ? handleExpand(featureName) : calculateFeature(endpoint, setFeatureStateMap[featureName], featureName, creditsRequired)}
            disabled={loadingFeatures[featureName] || (isBlocked && !content)}
            className={`person-read-button ${content ? 'bg-green-500' : 'bg-blue-500'} text-white rounded px-4 py-2 mt-2`}
        >
            {loadingFeatures[featureName] ? (
                <>
                    <div className="person-spinner border-t-2 border-blue-500 border-2 w-4 h-4 rounded-full animate-spin inline-block"></div>
                    {t('calculating')}
                </>
            ) : content ? (
                t('read')
            ) : isBlocked ? (
                t('blocked')
            ) : credits < creditsRequired ? (
                t('add_credits')
            ) : (
                t('calculate_credits', { count: creditsRequired })
            )}
        </button>
    );

    const setFeatureStateMap = {
        general_description: setGeneralDescription,
        strengths: setStrengths,
        weaknesses: setWeaknesses,
        risks: setRisks,
        how_to_make_it_work: setHowToMakeItWork,
    };

    const handleAddFix = async (e) => {
        e.preventDefault();
        if (!newFixSituation) return;

        setErrorMessage('');  // Clear any previous error message

        if (credits < 2) {
            navigate('/mycredits');
            return;
        }

        setLoadingFeatures((prev) => ({ ...prev, add_fix: true }));

        try {
            const userDoc = await getDoc(doc(db, 'ep', currentUser.uid));
            const targetUserDoc = await getDoc(doc(db, 'ep', relationshipData[currentPersonId].target));

            if (!userDoc.exists() || !targetUserDoc.exists()) {
                throw new Error('User data not found');
            }

            const userUPN = userDoc.data().upn;
            const targetUPN = targetUserDoc.data().upn;
            const status = statusMap[relationshipData[currentPersonId].status];
            const name = relationshipData[currentPersonId].name;

            // Check if the situation can be handled
            const checkParams = new URLSearchParams({
                situation: newFixSituation,
                // language: language,
            }).toString();

            const checkApiUrl = `${domain}/api/check_if_situation?${checkParams}`;
            const checkResponse = await fetch(checkApiUrl);
            console.log('checkResponse:', checkResponse);

            if (!checkResponse.ok) {
                throw new Error('Failed to check the situation');
            }

            const checkData = await checkResponse.json();
            console.log('checkData:', checkData);

            if (!checkData.result) {
                console.log('Situation not handled:', checkData);
                setErrorMessage(t('person.situation_not_handled'));
                setLoadingFeatures((prev) => ({ ...prev, add_fix: false }));
                return;
            }

            const relationshipParams = new URLSearchParams({
                upn: userUPN,
                upn2: targetUPN,
                status,
                name,
                language: language,
                situation: newFixSituation,
            }).toString();

            const apiUrl = `${domain}/api/relationship_fix?${relationshipParams}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const result = data.result;

            const newFix = {
                date_asked: Timestamp.now(),
                situation: newFixSituation,
                result,
            };

            await addDoc(collection(db, 'rel', currentPersonId, 'fixes'), newFix);

            setFixes(prevFixes => [{ ...newFix, id: Date.now().toString(), date: newFix.date_asked.toDate() }, ...prevFixes]);
            const userDocRef = doc(db, 'ep', currentUser.uid);
            await updateDoc(userDocRef, {
                credits: credits - 2,
            });

            setCredits((prevCredits) => prevCredits - 2);
            setNewFixSituation('');
        } catch (error) {
            console.error('Error adding new fix:', error);
            alert('Failed to add new fix: ' + error.message);
        } finally {
            setLoadingFeatures((prev) => ({ ...prev, add_fix: false }));
        }
    };


    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5">{t('person.title', { personName })}</h1>
                <p className="text-xl mb-10 text-left">{t('person.description', { personName })}</p>

                <div className="mb-20 mt-12 text-left">
                    <h2 className="text-3xl mb-4">{t('person.relationship_description')}</h2>
                    <p className="text-xl mb-4">{t('person.relationship_description_info')}</p>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 items-stretch">
                        <div className={`relative ${expandedFeature === 'general_description' ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                            {expandedFeature === 'general_description' ? (
                                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand('general_description')}>
                                    &times;
                                </button>
                            ) : ""}
                            <div className='person-feature-card p-6 bg-white rounded-lg shadow-md h-full'>
                                <h3 className="font-medium text-2xl mb-4">{t('person.general_description')}</h3>
                                {expandedFeature === 'general_description' ? (
                                    <>
                                        <div className="person-feature-content relative">
                                            {renderContent(generalDescription)}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand('general_description')}>{t('close')}</button>
                                        </div>
                                    </>
                                ) : (
                                    renderFeatureButton('general_description', generalDescription, 'relationship_description', 2)
                                )}
                            </div>
                        </div>

                        <div className={`relative ${expandedFeature === 'strengths' ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                            {expandedFeature === 'strengths' ? (
                                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand('strengths')}>
                                    &times;
                                </button>
                            ) : ""}
                            <div className='person-feature-card p-6 bg-white rounded-lg shadow-md h-full'>
                                <h3 className="font-medium text-2xl mb-4">{t('person.strengths')}</h3>
                                {expandedFeature === 'strengths' ? (
                                    <>
                                        <div className="person-feature-content relative text-md">
                                            {renderContent(strengths)}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand('strengths')}>{t('close')}</button>
                                        </div>
                                    </>
                                ) : (
                                    renderFeatureButton('strengths', strengths, 'relationship_strength', 2)
                                )}
                            </div>
                        </div>

                        <div className={`relative ${expandedFeature === 'weaknesses' ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                            {expandedFeature === 'weaknesses' ? (
                                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand('weaknesses')}>
                                    &times;
                                </button>
                            ) : ""}
                            <div className='person-feature-card p-6 bg-white rounded-lg shadow-md h-full'>
                                <h3 className="font-medium text-2xl mb-4">{t('person.weaknesses')}</h3>
                                {expandedFeature === 'weaknesses' ? (
                                    <>
                                        <div className="person-feature-content relative">
                                            {renderContent(weaknesses)}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand('weaknesses')}>{t('close')}</button>
                                        </div>
                                    </>
                                ) : (
                                    renderFeatureButton('weaknesses', weaknesses, 'relationship_weakness', 2)
                                )}
                            </div>
                        </div>

                        <div className={`relative ${expandedFeature === 'risks' ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                            {expandedFeature === 'risks' ? (
                                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand('risks')}>
                                    &times;
                                </button>
                            ) : ""}
                            <div className='person-feature-card p-6 bg-white rounded-lg shadow-md h-full'>
                                <h3 className="font-medium text-2xl mb-4">{t('person.risks')}</h3>
                                {expandedFeature === 'risks' ? (
                                    <>
                                        <div className="person-feature-content relative">
                                            {renderContent(risks)}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand('risks')}>{t('close')}</button>
                                        </div>
                                    </>
                                ) : (
                                    renderFeatureButton('risks', risks, 'relationship_risk', 2)
                                )}
                            </div>
                        </div>

                        <div className={`relative ${expandedFeature === 'how_to_make_it_work' ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                            {expandedFeature === 'how_to_make_it_work' ? (
                                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand('how_to_make_it_work')}>
                                    &times;
                                </button>
                            ) : ""}
                            <div className='person-feature-card p-6 bg-white rounded-lg shadow-md h-full'>
                                <h3 className="font-medium text-2xl mb-4">{t('person.how_to_make_it_work')}</h3>
                                {expandedFeature === 'how_to_make_it_work' ? (
                                    <>
                                        <div className="person-feature-content relative">
                                            {renderContent(howToMakeItWork)}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand('how_to_make_it_work')}>{t('close')}</button>
                                        </div>
                                    </>
                                ) : (
                                    renderFeatureButton('how_to_make_it_work', howToMakeItWork, 'relationship_how', 2)
                                )}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mb-20 mt-12 text-left">
                    <h2 className="text-3xl mb-4">{t('person.fix_relationship')}</h2>
                    <p className="text-xl mb-4">{t('person.fix_relationship_info')}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="person-feature-card p-6 bg-white rounded-lg shadow-md text-center col-span-1">
                            <h3 className="font-medium text-2xl mb-4">{t('person.add_fix')}</h3>
                            <form onSubmit={handleAddFix} className="person-add-fix-form">
                                <textarea
                                    value={newFixSituation}
                                    onChange={(e) => setNewFixSituation(e.target.value)}
                                    placeholder={t('person.describe_situation')}
                                    className="w-full mb-2 p-2 border border-gray-300 rounded"
                                    required
                                />
                                <button type="submit" disabled={loadingFeatures.add_fix} className="bg-blue-500 text-white rounded px-4 py-2">
                                    {loadingFeatures.add_fix ? (
                                        <>
                                            <div className="person-spinner border-t-2 border-blue-500 border-2 w-4 h-4 rounded-full animate-spin inline-block"></div>
                                            {t('calculating')}
                                        </>
                                    ) : (
                                        `${t('person.add_fix_button')} (2c)`
                                    )}
                                </button>
                            </form>
                            {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
                        </div>
                        {fixes.map((fix) => (
                            <div key={fix.id} className={`relative ${expandedFeature === fix.id ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                                {expandedFeature === fix.id && (
                                    <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand(fix.id)}>
                                        &times;
                                    </button>
                                )}
                                <div className='person-feature-card p-6 bg-white rounded-lg shadow-md'>
                                    <p>Date: {fix.date ? fix.date.toLocaleDateString() : ''}</p>
                                    <p>{expandedFeature === fix.id ? fix.situation : `${fix.situation.substring(0, 60)}...`}</p>
                                    {expandedFeature !== fix.id && (
                                        <button onClick={() => handleExpand(fix.id)} className="person-read-button bg-green-500 text-white rounded px-4 py-2 mt-2">
                                            {t('read')}
                                        </button>
                                    )}
                                    {expandedFeature === fix.id && (
                                        <div className="person-feature-content">
                                            {renderContent(fix.result)}
                                            <div className='flex justify-center items-center'>
                                                <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand(fix.id)}>{t('close')}</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-20 mt-12 text-left">
                    <h2 className="text-3xl mb-4">{t('person.relationship_time_travel')}</h2>
                    <p className="text-xl mb-4">{t('person.relationship_time_travel_info')}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="person-feature-card p-6 bg-white rounded-lg shadow-md text-center col-span-1">
                            <button className="bg-gray-300 text-gray-700 rounded px-4 py-2 cursor-not-allowed" disabled>{t('coming_soon')}</button>
                        </div>
                    </div>
                </div>

                <div className="mb-20 mt-12 text-left">
                    <h2 className="text-3xl mb-4">{t('person.personality_title', { personName })}</h2>
                    <p className="text-xl mb-4">{t('person.personality_info', { personName })}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {loading ? (
                            <p className="text-xl">{t('loading')}</p>
                        ) : personalityAllowed ? (
                            Object.entries(personality).map(([key, value]) => renderPersonalityFeature(key, value))
                        ) : (
                            <p className="text-xl">{t('person.no_personality_info')}</p>
                        )}
                    </div>
                </div>

                <div className="mb-20 mt-12 text-left">
                    <h2 className="text-3xl mb-4">{t('person.person_time_travel', { personName })}</h2>
                    <p className="text-xl mb-4">{t('person.person_time_travel_info', { personName })}</p>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="person-feature-card p-6 bg-white rounded-lg shadow-md text-center">
                            <button className="bg-gray-300 text-gray-700 rounded px-4 py-2 cursor-not-allowed" disabled>{t('coming_soon')}</button>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-md text-center">
                        <h2 className="text-2xl mb-4">{t('popup.calculation_running')}</h2>
                        <p className="text-xl mb-4">{t('popup.wait_to_complete')}</p>
                        <button onClick={() => setShowPopup(false)} className="bg-blue-500 text-white rounded px-4 py-2">{t('ok')}</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PersonPage;
