import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, setDoc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { decode } from 'base-64';
import Header from './components/Header';
import Footer from './components/Footer';
import { useTranslation } from 'react-i18next';
import googleLogo from './assets/google.png';
import LanguageContext from './LanguageContext';
import axios from 'axios';

function decodeAffiliateCode(code) {
    try {
        return decode(code);
    } catch (error) {
        //console.error("Invalid affiliate code");
        return null;
    }
}

function mapFirebaseErrorToTranslationKey(errorMessage) {
    switch (errorMessage) {
        case 'Firebase: Error (auth/email-already-in-use).':
            return 'signup.error_email_in_use';
        case 'Firebase: Error (auth/invalid-email).':
            return 'signup.error_invalid_email';
        case 'Firebase: Error (auth/weak-password).':
            return 'signup.error_weak_password';
        default:
            return 'signup.error_generic';
    }
}

const mailingListUids = {
    fr: '665ed85298286',
    en: '665ed80a43c9c',
    it: '665ed92931fc6',
    es: '665ed8a86d8a7',
    jp: '665ed90a0ba97',
    de: '665ed87f2c405',
    cn: '665ed8e67dfdc',
    pt: '665ed94e4573b'
};

function getMailingListUid(language) {
    return mailingListUids[language] || mailingListUids.en;
}

function SignUpPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [showVerificationPopup, setShowVerificationPopup] = useState(false);
    const { currentUser, signup, googleSignIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [referrer, setReferrer] = useState('');
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        if (currentUser && currentUser.emailVerified && !showVerificationPopup) {
            navigate('/dashboard');
        }
    }, [currentUser, navigate, showVerificationPopup]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const ref = searchParams.get('ref');
        if (ref) {
            const decodedRef = decodeAffiliateCode(ref);
            if (decodedRef) {
                setReferrer(decodedRef);
            }
        }
    }, [location.search]);

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            return setError(t('signup.passwords_do_not_match'));
        }
        if (!validatePassword(password)) {
            return setError(t('signup.password_requirements'));
        }
        setError('');
        try {
            const userCredential = await signup(email, password);
            const user = userCredential.user;

            await setDoc(doc(db, 'ep', user.uid), {
                credits: 5,
            });

            if (referrer) {
                const referrerDoc = doc(db, 'ep', referrer);
                await updateDoc(referrerDoc, {
                    credits: increment(1)
                });
            }

            // Add user to mailing list
            const listUid = getMailingListUid(language);
            await axios.post(`${process.env.REACT_APP_DOMAIN}/api/newsletter-signup`, { email, listUid });

            setShowVerificationPopup(true);
        } catch (err) {
            const translationKey = mapFirebaseErrorToTranslationKey(err.message);
            setError(t(translationKey));
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const userCredential = await googleSignIn();
            const user = userCredential.user;

            // Check if the user document exists in Firestore
            const userDocRef = doc(db, 'ep', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // If the document does not exist, set initial data for new user
                await setDoc(userDocRef, {
                    credits: 5,
                    upn: true, // Set this field to indicate the user has logged in for the first time
                });
            }

            // Add user to mailing list
            const listUid = getMailingListUid(language);
            await axios.post(`${process.env.REACT_APP_DOMAIN}/api/newsletter-signup`, { email: user.email, listUid });

            navigate('/test-alsamind');
        } catch (err) {
            const translationKey = mapFirebaseErrorToTranslationKey(err.message);
            setError(t(translationKey));
        }
    };

    const handlePopupClose = () => {
        setShowVerificationPopup(false);
        navigate('/login');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header />
            <div className="flex flex-col justify-center items-center w-full max-w-2xl mx-auto my-24 md:mt-36 p-6 bg-white shadow-md rounded-md">
                <h1 className="text-4xl text-center mb-8">{t('signup.title')}</h1>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <form onSubmit={handleSubmit} className="w-full flex flex-col items-start">
                    <input
                        type="email"
                        placeholder={t('signup.email_placeholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md"
                    />
                    <input
                        type="password"
                        placeholder={t('signup.password_placeholder')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md"
                    />
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <input
                        type="password"
                        placeholder={t('signup.confirm_password_placeholder')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md"
                    />
                    <button type="submit" className="w-full py-3 mt-6 bg-orange-500 text-white font-bold text-lg rounded-md hover:bg-orange-600">
                        {t('signup.signup_button')}
                    </button>
                </form>
                <div className="w-full flex flex-col items-center mt-6">
                    <p className="my-2">{t('signup.or')}</p>
                    <button className="flex items-center justify-center w-full py-2 bg-white border border-gray-300 rounded-md hover:bg-blue-500 hover:text-white" onClick={handleGoogleSignIn}>
                        <img src={googleLogo} alt="Google logo" className="w-6 h-6 mr-2" />
                        {t('signup.google_signup_button')}
                    </button>
                    <button className="w-full py-2 mt-4 bg-gray-300 text-black font-bold text-lg rounded-md hover:bg-gray-400" onClick={() => navigate('/login')}>
                        {t('signup.login_button')}
                    </button>
                </div>
            </div>
            {showVerificationPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg text-center shadow-lg">
                        <h2 className="text-2xl mb-4">{t('signup.verify_email')}</h2>
                        <p className="mb-4">{t('signup.verify_email_message')}</p>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded-md" onClick={handlePopupClose}>
                            {t('signup.ok_button')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SignUpPage;
