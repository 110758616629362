import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';
// import personalityImage from './assets/personality.png';
// import relationshipImage from './assets/relationship.png';
// import timetravelImage from './assets/timetravel.png';
// import chatImage from './assets/chat.png';
// import healthImage from './assets/health.png';
// import wealthImage from './assets/wealth.png';
// import yourdayImage from './assets/yourday.png';

function DashboardPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentUser } = useAuth();

    useEffect(() => {
        const checkUserUpn = async () => {
            if (currentUser) {
                const userDocRef = doc(db, 'ep', currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (!userDoc.exists() || !userDoc.data().upn) {
                    navigate('/test-alsamind');
                }
            }
        };

        checkUserUpn();
    }, [currentUser, navigate]);

    const handleButtonClick = (path) => {
        navigate(path);
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl mb-5">{t('dashboard.welcome_title')}</h1>
                <p className="text-xl mb-10 text-left">{t('dashboard.welcome_message')}</p>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.your_personality')}</h2>
                        {/* <img src={personalityImage} alt={t('dashboard.your_personality')} className="mx-auto mb-4"/> */}
                        <button
                            onClick={() => handleButtonClick('/mypersonality')}
                            className="px-5 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                            {t('dashboard.open_button')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.your_relationships')}</h2>
                        {/* <img src={relationshipImage} alt={t('dashboard.your_relationships')} className="mx-auto mb-4"/> */}
                        <button
                            onClick={() => handleButtonClick('/myrelationships')}
                            className="px-5 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                            {t('dashboard.open_button')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.time_travel')}</h2>
                        {/* <img src={timetravelImage} alt={t('dashboard.time_travel')} className="mx-auto mb-4"/> */}
                        <button disabled className="px-5 py-2 bg-gray-400 text-white rounded-lg">
                            {t('dashboard.coming_soon')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.your_day')}</h2>
                        {/* <img src={yourdayImage} alt={t('dashboard.your_day')} className="mx-auto mb-4"/> */}
                        <button disabled className="px-5 py-2 bg-gray-400 text-white rounded-lg">
                            {t('dashboard.coming_soon')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.chat_with_your_life')}</h2>
                        {/* <img src={chatImage} alt={t('dashboard.chat_with_your_life')} className="mx-auto mb-4"/> */}
                        <button disabled className="px-5 py-2 bg-gray-400 text-white rounded-lg">
                            {t('dashboard.coming_soon')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.your_health')}</h2>
                        {/* <img src={healthImage} alt={t('dashboard.your_health')} className="mx-auto mb-4"/> */}
                        <button disabled className="px-5 py-2 bg-gray-400 text-white rounded-lg">
                            {t('dashboard.coming_soon')}
                        </button>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow text-center">
                        <h2 className="text-2xl mb-5">{t('dashboard.your_wealth')}</h2>
                        {/* <img src={wealthImage} alt={t('dashboard.your_wealth')} className="mx-auto mb-4"/> */}
                        <button disabled className="px-5 py-2 bg-gray-400 text-white rounded-lg">
                            {t('dashboard.coming_soon')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;
