import React from 'react';
import Header from './components/Header';
import './css/Legal.css';
import { useTranslation } from 'react-i18next';

function Legal() {
    const { t } = useTranslation();

    return (
        <div>
            <Header />
            <section className="legal-section">
                <h1>{t('legal.LegalInformation')}</h1>
                <p>{t('legal.CompanyInfo')}</p>
                <div className="legal-content">
                    <h2>{t('legal.TermsPrivacyPolicy')}</h2>
                    <p>{t('legal.LastUpdated', { date: '29/05/2024' })}</p>
                    <h3>{t('legal.Article1Title')}</h3>
                    <p>{t('legal.Article1Content')}</p>
                    <h3>{t('legal.Article2Title')}</h3>
                    <p>{t('legal.Article2Content')}</p>
                    <h3>{t('legal.Article3Title')}</h3>
                    <p>{t('legal.Article3Content')}</p>
                    <h3>{t('legal.Article4Title')}</h3>
                    <p>{t('legal.Article4Content')}</p>
                    <h3>{t('legal.Article5Title')}</h3>
                    <p>{t('legal.Article5Content')}</p>
                    <h3>{t('legal.Article6Title')}</h3>
                    <p>{t('legal.Article6Content')}</p>
                    <h3>{t('legal.Article7Title')}</h3>
                    <p>{t('legal.Article7Content')}</p>
                    <h3>{t('legal.Article8Title')}</h3>
                    <p>{t('legal.Article8Content')}</p>
                    <h3>{t('legal.Article9Title')}</h3>
                    <p>{t('legal.Article9Content')}</p>
                    <h3>{t('legal.Article10Title')}</h3>
                    <p>{t('legal.Article10Content')}</p>
                    <h3>{t('legal.Article11Title')}</h3>
                    <p>{t('legal.Article11Content')}</p>
                </div>
            </section>
        </div>
    );
}

export default Legal;
