import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import googleLogo from './assets/google.png';
import axios from 'axios';
import LanguageContext from './LanguageContext';

function mapFirebaseErrorToTranslationKey(errorMessage) {
    switch (errorMessage) {
        case 'Firebase: Error (auth/invalid-credential).':
            return 'login.error_user_not_found';
        case 'Firebase: Error (auth/network-request-failed).':
            return 'login.error_verify';
        case 'Please verify your email before logging in.':
            return 'login.error_verify';
        default:
            return 'login.error_generic';
    }
}

const mailingListUids = {
    fr: '665ed85298286',
    en: '665ed80a43c9c',
    it: '665ed92931fc6',
    es: '665ed8a86d8a7',
    jp: '665ed90a0ba97',
    de: '665ed87f2c405',
    cn: '665ed8e67dfdc',
    pt: '665ed94e4573b'
};

function getMailingListUid(language) {
    return mailingListUids[language] || mailingListUids.en;
}

function LoginPage() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [verificationSent, setVerificationSent] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const { login, googleSignIn, resendVerificationEmail } = useAuth();
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setShowResendButton(false);  // Reset the resend button visibility
        setVerificationSent(false);  // Reset the verification sent message visibility
        try {
            const userCredential = await login(email, password);
            const user = userCredential.user;

            const userDocRef = doc(db, 'ep', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists() && userDoc.data().upn) {
                navigate('/dashboard');
            } else {
                navigate('/test-alsamind');
            }
        } catch (err) {
            const translationKey = mapFirebaseErrorToTranslationKey(err.message);
            setError(t(translationKey));
            if (err.message === 'Please verify your email before logging in.') {
                setShowResendButton(true);  // Show the resend button if email verification is needed
            }
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const userCredential = await googleSignIn();
            const user = userCredential.user;

            const userDocRef = doc(db, 'ep', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                await setDoc(userDocRef, {
                    credits: 5,
                    upn: true,
                });
            }

            // Add user to mailing list
            const listUid = getMailingListUid(language);
            await axios.post(`${process.env.REACT_APP_DOMAIN}/api/newsletter-signup`, { email: user.email, listUid });

            navigate('/test-alsamind');
        } catch (err) {
            const translationKey = mapFirebaseErrorToTranslationKey(err.message);
            setError(t(translationKey));
        }
    };

    const handleResendVerification = async () => {
        try {
            await resendVerificationEmail(email);
            setVerificationSent(true);
            setShowResendButton(false);
            setError('');  // Clear the error message
        } catch (err) {
            setError(t('login.error_resend_verification'));
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header />
            <div className="flex flex-col justify-center items-center w-full max-w-2xl mx-auto my-24 md:mt-36 p-6 bg-white shadow-md rounded-md">

                <h1 className="text-4xl text-center mb-8">{t('login.title')}</h1>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {showResendButton && !verificationSent && (
                    <button className="bg-white text-black border border-black rounded-full py-2 px-4 mb-4 hover:bg-blue-700" onClick={handleResendVerification}>
                        {t('login.resend_verification')}
                    </button>
                )}
                {verificationSent && <p className="text-green-500 text-center mb-4">{t('login.verification_sent')}</p>}
                <form onSubmit={handleSubmit} className="w-full flex flex-col items-start">
                    <input
                        type="email"
                        placeholder={t('login.email_placeholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md"
                    />
                    <input
                        type="password"
                        placeholder={t('login.password_placeholder')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md"
                    />
                    <p className="text-sm text-gray-600 mt-4 cursor-pointer" onClick={() => navigate('/forgot-password')}>{t('login.forgot_password')}</p>
                    <button type="submit" className="w-full py-3 my-4 bg-[#ff5722] text-white font-bold text-lg rounded-md hover:bg-[#ff5622ec]">
                        {t('login.login_button')}
                    </button>
                </form>
                <div className="w-full flex flex-col items-center">
                    <p className="mb-2">{t('login.or')}</p>
                    <button className="flex items-center justify-center w-full py-2 bg-white border border-[#ff5722] rounded-md hover:bg-gray-100 hover:text-gray-700" onClick={handleGoogleSignIn}>
                        <img src={googleLogo} alt="Google logo" className="w-6 h-6 mr-2" />
                        {t('login.google_login_button')}
                    </button>
                    <button className="w-full py-2 mt-4 border border-[#ff5722] text-black font-bold text-lg rounded-md" onClick={() => navigate('/signup')}>
                        {t('login.signup_button')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
