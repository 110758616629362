import React from 'react';
import { useTranslation } from 'react-i18next';
import './TwoColumnSection.css';

function TwoColumnSection() {
    const { t } = useTranslation();

    return (
        <section className="two-column-section" id="contact">
            <div className="text-container">
                <h2>{t('twoColumnSection.title')}</h2>
                <p>{t('twoColumnSection.description')}</p>
                <ul>
                    <li>{t('twoColumnSection.item1')}</li>
                    <li>{t('twoColumnSection.item2')}</li>
                    <li>{t('twoColumnSection.item3')}</li>
                    <li>{t('twoColumnSection.item4')}</li>
                    <li>{t('twoColumnSection.item5')}</li>
                </ul>
            </div>
            <div className="video-container">
                <iframe
                    width="100%"
                    height="315"
                    src={t('twoColumnSection.videoUrl')}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </section>
    );
}

export default TwoColumnSection;
