// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blue-section {
    background: linear-gradient(to right, rgb(64, 133, 156), rgb(67, 0, 139));
    color: white;
    text-align: center;
    padding: 30px 20px;
    font-size: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/components/BlueSection.css"],"names":[],"mappings":"AAAA;IACI,yEAAyE;IACzE,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".blue-section {\n    background: linear-gradient(to right, rgb(64, 133, 156), rgb(67, 0, 139));\n    color: white;\n    text-align: center;\n    padding: 30px 20px;\n    font-size: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
