import React from 'react';
import { useAuth } from './AuthContext';
import Header from './components/Header';
import Header2 from './components/Header2';
import Hero from './components/Hero';
import WhatAlsaMindDoes from './components/WhatAlsaMindDoes';
import BlueSection from './components/BlueSection';
import VideoSection from './components/VideoSection';
import CenteredBlueSection from './components/CenteredBlueSection';
import TwoColumnSection from './components/TwoColumnSection';

//THIS PAGE USES COMPONENTS FROM THE COMPONENTS FOLDER

function HomePage() {
    const { currentUser } = useAuth();

    return (
        <div>
            {currentUser ? <Header2 /> : <Header />}
            <Hero />
            <WhatAlsaMindDoes />
            <BlueSection />
            <VideoSection />
            <CenteredBlueSection />
            <TwoColumnSection />
        </div>
    );
}

export default HomePage;
